import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authReducer } from "../reducers/authReducer";
import { emsApi } from "../services/ems.service";
import { authApi } from "../services/auth.service";
import { topologyApi } from "../services/topology.service";
import { parametrageApi } from "../services/parametrage.service";
import { usersApi } from "../services/users.service";
import { topologyReducer } from "../reducers/topologyReducer";
import { crudApi } from "../services/crud.service";
import { weatherApi } from "../services/weather.service";
import { persistStore, persistReducer } from "redux-persist";
import localforage from 'localforage';
import { coachingApi } from "../services/coaching.service";
const persistConfig = {
  key: "auth",
  storage:localforage,
};
const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [emsApi.reducerPath]: emsApi.reducer,
  [topologyApi.reducerPath]: topologyApi.reducer,
  [crudApi.reducerPath]: crudApi.reducer,
  [parametrageApi.reducerPath]: parametrageApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [weatherApi.reducerPath]: weatherApi.reducer,
  [coachingApi.reducerPath]:coachingApi.reducer,
  auth: authReducer,
  topology: topologyReducer,
});
export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
      .concat(emsApi.middleware)
      .concat(authApi.middleware)
      .concat(topologyApi.middleware)
      .concat(parametrageApi.middleware)
      .concat(usersApi.middleware)
      .concat(crudApi.middleware) 
      .concat(weatherApi.middleware)
      .concat(coachingApi.middleware)
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);
/*
const unsubscribe = store.subscribe(() =>
  console.log('State after dispatch: ', store.getState())
) 
*/
export type RootState = ReturnType<typeof store.getState>;
