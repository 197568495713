import { TYPES, getNodeTypeByString } from "../utils/toolbox";
import { CustomButton } from "../components/CustomButton";
import { OptionType } from "../components/RadioForm";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useTransition } from "react";
import { AdvancedFluxNodeDto, topologyApi } from "../services/topology.service";
import { ParameterDescriptor } from "./FluxTopologyPage";
import EmsAlert from "../components/EmsAlert";
interface TopologyConfigParamsProps {
  options : OptionType[]
  setOptions : React.Dispatch<React.SetStateAction<OptionType[]>>
  typeTopology: string;
  selectedModele: string;
  valider: (
    selectedNode: string,
    params: {
      GROUP: AdvancedFluxNodeDto;
      CONSUMER: AdvancedFluxNodeDto;
      PRODUCER?: AdvancedFluxNodeDto;
      BATTERY?: AdvancedFluxNodeDto;
    },
    formValid: boolean,
    configFinished : boolean
  ) => void;
}
export const TopologyConfigParams: React.FC<TopologyConfigParamsProps> = ({
  options,
  setOptions,
  selectedModele,
  typeTopology,
  valider,
}) => {
  const [isPending, startTransition] = useTransition();
  const [selectedElement, setSelectedElement] = useState<
    "GROUP" | "CONSUMER" | "PRODUCER" | "BATTERY"
  >("GROUP");

  const [getByCritere, getByCriteresResponse] =
    topologyApi.useGetByCritereMutation();
 
  const initialData: {
    GROUP: AdvancedFluxNodeDto;
    CONSUMER: AdvancedFluxNodeDto;
    PRODUCER?: AdvancedFluxNodeDto;
    BATTERY?: AdvancedFluxNodeDto;
  } = {
    GROUP: {
      typeNoeud: "GROUP",
      fluxNodeParameters: [],
      measurementSourceParameters: [],
    },
    CONSUMER: {
      typeNoeud: "CONSUMER",
      fluxNodeParameters: [],
      measurementSourceParameters: [],
    },
    PRODUCER: {
      typeNoeud: "PRODUCER",
      fluxNodeParameters: [],
      measurementSourceParameters: [],
    },
    BATTERY: {
      typeNoeud: "BATTERY",
      fluxNodeParameters: [],
      measurementSourceParameters: [],
    },
  };
  const alreadyExecuted = useRef(false);
  const [allData, setAllData] = useState<{
    GROUP: AdvancedFluxNodeDto;
    CONSUMER: AdvancedFluxNodeDto;
    PRODUCER?: AdvancedFluxNodeDto;
    BATTERY?: AdvancedFluxNodeDto;
  }>(initialData);
  //change initial data

  useEffect(() => {
    let newData = { ...initialData  };
    if (selectedModele === "pv") {
      delete newData.BATTERY;
    } else if (selectedModele === "batt") {
      delete newData.PRODUCER;
    }
    setAllData((prev) => ({ ...newData }));
    setSelectedElement("GROUP");
    resetOptions()
  }, [selectedModele]);
  useEffect(() => {
    setAllData((prev) => ({ ...initialData }));
    setSelectedElement("GROUP");
    resetOptions()
  }, [typeTopology]);


  useEffect(() => {
    getByCritere({
      uri: "parameterDescriptor",
      body: {
        fluxNodeTypes:
        
          selectedElement == "CONSUMER"
            ? ["ALL_ENERGY_ELEMENTS", "CONSUMER", "CONSUMER_" + typeTopology]
            : selectedElement == "PRODUCER"
            ? ["ALL_ENERGY_ELEMENTS", "PRODUCER", "PRODUCER_PV"]
            : selectedElement == "GROUP"
            ? ["GROUP"]
            : ["ALL_ENERGY_ELEMENTS", "BATTERY"],
      },
    })
      .unwrap()
      .then((res) => {
        res.forEach((p) => {
          let param: ParameterDescriptor = p as ParameterDescriptor;

          let value: string = param.defaultValue;
          const node = allData[getNodeTypeByString(selectedElement)];
          let fluxNodeParam = node?.fluxNodeParameters.find(
            (p) => p.parameterDescriptor.id == param.id
          );
          if (fluxNodeParam != undefined) {
            if (fluxNodeParam.value != undefined ) {
              value = fluxNodeParam.value + "";
            }
          }
          if (TYPES.BOOLEAN.includes(param.type)) {
            onchange(
              param.name,
              value && value.toLowerCase() === "true" ? "true" : "false",
              param
            );
          } else {
            onchange(param.name, value, param);
          }
        });
      
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedElement, typeTopology,selectedModele]);
  

  useEffect(() => { 
    getByCritere({
      uri: "parameterDescriptor",
      body: {
        measurementSourceTypes: (
          selectedElement == "CONSUMER"
            ? ["ALL", "BOX_EMS_COMPOSITE"]
            : selectedElement == "PRODUCER"
            ? ["ALL", "BOX_EMS" ]
            : selectedElement == "GROUP"
            ? ["ALL","BOX_EMS","NULL"]
            : ["ALL", "BOX_EMS","BATTERY","BATTERY_MODEL","BATTERY_ON_CALL" ]
        )  
      },
    })
      .unwrap()
      .then((res) => {
        res.forEach((p) => {
          let param: ParameterDescriptor = p as ParameterDescriptor;
          let value: string = param.defaultValue + "";
          const node = allData[getNodeTypeByString(selectedElement)];
          let msParam = node?.measurementSourceParameters.find(
            (p) => p.parameterDescriptor.id == param.id
          );
          if (msParam != undefined) {
            if (msParam.value) {
              value = msParam.value + "";
            }
          }
          if (TYPES.BOOLEAN.includes(param.type)) {
            onMSchange(
              param.name,
              value && value.toLowerCase() === "true" ? "true" : "false",
              param
            );
          } else {
            onMSchange(param.name, value, param);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedElement, typeTopology,selectedModele]);
  useEffect(() => {
    if (options.length > 0 && !alreadyExecuted.current) {
      updateOptions(!validateForm());

      alreadyExecuted.current = true;
    }
  }, [options]);
  useEffect(() => {
    alreadyExecuted.current = false;
  }, [selectedModele,typeTopology]);



  const onMSchange = (
    name: string,
    value: string,
    parameterDescriptor: ParameterDescriptor
  ) => {
    const node = allData[getNodeTypeByString(selectedElement)];
    if (
      node?.measurementSourceParameters.some(
        (p) => p.parameterDescriptor.id == parameterDescriptor.id
      )
    ) {
      node.measurementSourceParameters.map((p) => {
        if (p.parameterDescriptor.id == parameterDescriptor.id) {
          p.value = value;
        }
        return p;
      });
    } else {
      node?.measurementSourceParameters.push({
        measurementSourceDTO: {},
        parameterDescriptor: parameterDescriptor,
        value,
      });
    }

    setAllData((prev) => ({
      ...prev,
      [selectedElement]: {
        ...node,
        measurementSourceParameters: node?.measurementSourceParameters,
      },
    }));
  };
  const getValueByParameterDescriptor = (
    parameterDescriptor: ParameterDescriptor
  ) => {
    const node = allData[getNodeTypeByString(selectedElement)];
    if (
      node?.fluxNodeParameters.some(
        (p) => p.parameterDescriptor.id == parameterDescriptor.id
      )
    ) {
      let value: string = "";
      node.fluxNodeParameters.forEach((p) => {
        if (p.parameterDescriptor.id == parameterDescriptor.id) {
          value = p.value + "";
        }
      });
      return value;
    }
    return "";
  };
  const onchange = (
    name: string,
    value: string,
    parameterDescriptor: ParameterDescriptor
  ) => {
    const node = allData[getNodeTypeByString(selectedElement)];
    if (
      node?.fluxNodeParameters.some(
        (p) => p.parameterDescriptor.id == parameterDescriptor.id
      )
    ) {
      node.fluxNodeParameters.map((p) => {
        if (p.parameterDescriptor.id == parameterDescriptor.id) {
          p.value = value;
        }
        return p;
      });
    } else {
      node?.fluxNodeParameters.push({
        fluxNode: {},
        parameterDescriptor: parameterDescriptor,
        value,
      });
    }
    setAllData((prev) => ({
      ...prev,
      [selectedElement]: {
        ...node,
        fluxNodeParameters: node?.fluxNodeParameters,
      },
    })); 
    valider(selectedElement, allData, validateForm(),updateOptions(!validateForm()));
  };
  const resetOptions = ()=>{
    if(options && options.length >0) {
      let newOptions =   options.map(opt=>{
        let newOpt =  {...opt,status : "started"}
        return newOpt as OptionType 
      })
      setOptions(prev => ([...newOptions]))
    }
  }
  const updateOptions = (formValid:boolean)=>{
    
    if(options && options.length >0) {
      let confiFinished = true
      let newOptions =   options.map(opt=>{
        let newOpt = opt
        if(opt.value == selectedElement) {
          if(!formValid){
            newOpt =  {...opt,status : "error"}
          } else {
            newOpt =  {...opt,status : "completed"}
          }
        }else {
          newOpt =  opt
        }
        if(newOpt.status != "completed") {
          confiFinished = false
        }
        return newOpt
      })
      setOptions(prev => ([...newOptions]))
      return confiFinished
    }
   return false
  }
  const validateForm = () => {
    let isInvalid = false;
    allData[getNodeTypeByString(selectedElement)]?.fluxNodeParameters.forEach(
      (p) => {
        if (
          p.parameterDescriptor.requirement &&
          getValueByParameterDescriptor(p.parameterDescriptor) + "" === ""
        ) {
          isInvalid = true;
        }
      }
    );
    return isInvalid;
  };
  return (
    <div>
      <h1 className="text-left    text-xl md:text-2xl   text-[#003265] font-bold font-extrabold text-xl my-auto">
        Configuration de l'installation
      </h1>
      <FormControl className="control">
        <div className="flex flex-row gap-2">
          {options.map((opt, index) => {
            return (
              <CustomButton
                key={index}
                colorScheme=""
                style={{
                  backgroundColor:
                    opt.value == selectedElement
                      ? "#003265"
                      : opt.status == "completed"
                      ? "green" : 
                      opt.status =="error" ? "red"
                      : "#dfe3e8",
                  color:
                    opt.value == selectedElement
                      ? "white"
                      : opt.status == "completed" || opt.status == "error"
                      ? "white"
                      : "black",
                  borderColor: "red",
                }}
                onClick={() => {
                  //setShowMessageElementConfigurationCompleted(null);
                  startTransition(() => {
                    setSelectedElement(getNodeTypeByString(opt.value));
                  });
                }}
                text={opt.viewValue}
              />
            );
          })}
        </div>
      </FormControl>
      {getByCriteresResponse && getByCriteresResponse.isError ? (
        <EmsAlert
          className="mt-4"
          description="Erreur lors du chargement des données de paramètrage "
          status="error"
          title=""
        ></EmsAlert>
      ) : getByCriteresResponse && getByCriteresResponse.isLoading ? (
        <Stack className="pt-4">
          <div className="flex flex-row gap-4 ">
            <Skeleton height="20px" width={"60px"} />
            <Skeleton height="20px" width={"100%"} />
          </div>
          <div className="flex flex-row gap-4 ">
            <Skeleton height="20px" width={"60px"} />
            <Skeleton height="20px" width={"100%"} />
          </div>
          <div className="flex flex-row gap-4 ">
            <Skeleton height="20px" width={"60px"} />
            <Skeleton height="20px" width={"100%"} />
          </div>
          <div className="flex flex-row gap-4 ">
            <Skeleton height="20px" width={"60px"} />
            <Skeleton height="20px" width={"100%"} />
          </div>
        </Stack>
      ) : (
        <form id="paramsForm" className="flex flex-col gap-4">
          {allData && allData[
              getNodeTypeByString(selectedElement)
            ] && allData[
              getNodeTypeByString(selectedElement)
            ]?.fluxNodeParameters &&
            allData[
              getNodeTypeByString(selectedElement)
            ]?.fluxNodeParameters.map((p) => {
              let pd: ParameterDescriptor =
                p.parameterDescriptor as ParameterDescriptor;

              return pd.visibility ? (
                <FormControl
                  key={pd.id}
                  className="flex ms:flex-row items-center md:w-1/2 "
                >
                  <FormLabel className="min-w-fit">
                    <p className="font-extrabold flex flex-row items-center content-center">
                      {" "}
                      <div>
                        {" "}
                        {pd.viewName ? pd.viewName : pd.name}{" "}
                        {pd.viewUnit
                          ? "( " + pd.viewUnit + " )"
                          : pd.unit
                          ? "( " + pd.unit + " )"
                          : ""}{" "}
                      </div>{" "}
                      <div className="text-red-500 my-auto pl-2">
                        {pd.requirement ? " *" : ""}
                      </div>
                    </p>{" "}
                  </FormLabel>
                  <div className="flex flex-col w-full">
                    {TYPES.BOOLEAN.includes(pd.type.toLocaleUpperCase()) ? (
                      <div>
                        <Checkbox
                          isInvalid={
                            pd.requirement &&
                            getValueByParameterDescriptor(pd) + "" === ""
                          }
                          size="lg"
                          name={pd.name}
                          isChecked={p.value === "true" ? true : false}
                          onChange={(e) => {
                            onchange(pd.name, e.target.checked + "", pd);
                          }}
                        ></Checkbox>
                        {pd.requirement &&
                          getValueByParameterDescriptor(pd) + "" === "" && (
                            <div className="text-red-500">
                              Ce champ est obligatoire
                            </div>
                          )}
                      </div>
                    ) : TYPES.ENUM.includes(pd.type.toLocaleUpperCase()) ? (
                      <div className="flex flex-col w-full">
                        <FormControl className="flex ms:flex-row items-center">
                          <Select
                            isInvalid={
                              pd.requirement &&
                              getValueByParameterDescriptor(pd) + "" === ""
                            }
                            name={pd.name}
                            placeholder="Selectionner une option"
                            value={p.value + ""}
                            onChange={(e) => {
                              onchange(pd.name, e.target.value + "", pd);
                            }}
                          >
                            {pd.typeValues &&
                              pd.typeValues.split(";") &&
                              pd.typeValues.split(";").length > 0 &&
                              pd.typeValues.split(";").map((elt, index) => {
                                return (
                                  <option key={index} value={elt}>
                                    {elt}{" "}
                                    {pd.viewUnit
                                      ? "( " + pd.viewUnit + " )"
                                      : pd.unit
                                      ? "( " + pd.unit + " )"
                                      : ""}
                                  </option>
                                );
                              })}
                          </Select>
                        </FormControl>

                        {pd.requirement &&
                          getValueByParameterDescriptor(pd) + "" === "" && (
                            <div className="text-red-500">
                              Ce champ est obligatoire
                            </div>
                          )}
                      </div>
                    ) : (
                      <div>
                        <Input
                          isInvalid={
                            pd.requirement &&
                            getValueByParameterDescriptor(pd) + "" === ""
                          }
                          type={
                            TYPES.NUMBER.includes(pd.type.toUpperCase())
                              ? "number"
                              : "text"
                          }
                          name={pd.name}
                          required={pd.requirement}
                          onChange={(e: any) => {
                            onchange(pd.name, e.target.value + "", pd);
                          }}
                          value={getValueByParameterDescriptor(pd) + ""}
                        />
                        {pd.requirement &&
                          getValueByParameterDescriptor(pd) + "" === "" && (
                            <div className="text-red-500">
                              Ce champ est obligatoire
                            </div>
                          )}

                        {/* // {errors[selectedElement][pd.name] && (
                        //   <div className="text-red-500">
                        //     {errors[selectedElement][pd.name]}
                        //   </div>
                        // )} */}
                      </div>
                    )}
                  </div>
                </FormControl>
              ) : (
                <></>
              );
            })}
          {/* <div className="flex flex-row justify-start ">
          <Button
            type="button"
            isDisabled={validateForm()}
            onClick={(e) => {
              if (!validateForm()) {
                valider(selectedElement, allData);
              }  
            }}
            colorScheme="twitter"
            size={"lg"}
            // rightIcon={<ArrowForwardIcon />}
          >
            Valider
          </Button>
        </div> */}
        </form>
      )}
    </div>
  );
};
