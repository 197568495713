import React, { useEffect, useState } from "react";
import "../assets/scss/pages/WeatherPage.scss";
import { WeatherIndicator } from "../components/WeatherIndicator";
import { WeatherDetails } from "../components/WeatherDetails";
import {Switcher} from "../components/Switcher";
import { Card } from "../components/Card";
import { useLazyGetHomeBalanceDataQuery } from "../services/ems.service";
import { displayUnit, useSwitcherContext } from "../contexts/SwitcherContext";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { symbolMap } from "../utils/toolbox";
import { Skeleton } from "@chakra-ui/react";
import { useNavigate } from "react-router";
export const WeatherPage: React.FC = () => {
  const [homeBalanceData, setHomeBalanceData] = useState();
  const [
    getHomeBalanceData,
    { isLoading: homeBalanceLoading, isError: homeBalanceIsError },
  ] = useLazyGetHomeBalanceDataQuery();
  const { unit, period, switcherDate, setSwitcherDate } = useSwitcherContext();
  const topologyId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate()
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    getHomeBalanceData({ date: switcherDate, topologyId })
      .unwrap()
      .then((res) => {
        setHomeBalanceData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [switcherDate, topologyId]);
  return (
    <div className="flex flex-col w-full bg-gradient-to-b from-[#74B8FF] to-[#FFFFFF] to-100% ">
      <WeatherIndicator />
      {/***Mobile */}

      <div className=" flex flex-col gap-4 md:flex-row md:gap-4 md:justify-between md:hidden max-w-full px-2">
        <div className="container-weather__right w-full flex flex-col items-center py-2 mx-auto">
          <Card className=" w-full md:w-3/4 py-2">
            <Switcher />
          </Card>
          {homeBalanceIsError ? (
            <div>Error</div>
          ) : (
            <div className="flex flex-row gap-2 md:gap-4 justify-between py-2">
              <Skeleton
                isLoaded={!homeBalanceLoading}
                className="w-full h-full  py-2"
              >
                <Card className="w-full h-full py-2">
                  <div className="md:w-30  text-[#E02FEF] flex flex-col justify-between gap-2 px-auto py-auto h-full">
                    <div className="flex justify-center items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 11 16.5"
                      >
                        <path
                          id="Tracé_21968"
                          fill="#E02FEF"
                          data-name="Tracé 21968"
                          d="M10.25,6.5H6.671l-.215-2H8.5v.75a.25.25,0,0,0,.5,0v-1A.25.25,0,0,0,8.75,4H6.4L6,.224A.25.25,0,0,0,5.75,0h-1A.25.25,0,0,0,4.5.224L4.1,4H1.75a.25.25,0,0,0-.25.25v1a.25.25,0,0,0,.5,0V4.5H4.044l-.215,2H.25A.25.25,0,0,0,0,6.75v1a.25.25,0,0,0,.5,0V7H3.775l-.75,7H3a.75.75,0,0,0-.75.75v.75H.5a.25.25,0,0,0,0,.5H10a.25.25,0,1,0,0-.5H8.25v-.75A.75.75,0,0,0,7.5,14H7.475l-.75-7H10v.75a.25.25,0,0,0,.5,0v-1a.25.25,0,0,0-.25-.25ZM4.975.5h.55L5.9,4H4.6Zm-.429,4H5.954l.215,2H4.332Zm3.2,10.25v.75h-5v-.75A.25.25,0,0,1,3,14.5H7.5a.25.25,0,0,1,.25.25ZM6.971,14H3.529l.75-7H6.221Z"
                          transform="translate(0.25 0.25)"
                          stroke="#E02FEF"
                          stroke-width="0.5"
                        />
                      </svg>
                    </div>
                    <div className="flex flex-wrap text-center justify-center items-center font-semibold">
                      Surplus Potentiel
                    </div>
                    <div className="flex flex-row justify-center items-center bg-[#E02FEF] rounded-[20px] text-white font-bold max-w-fit px-2 md:px-4 mx-auto">
                      {homeBalanceData &&
                        (
                          homeBalanceData[period]["prod" + unit][
                            "provider"
                          ] as number
                        ).toFixed(1)}{" "}
                      {displayUnit(unit, symbolMap)}
                    </div>
                  </div>
                </Card>
              </Skeleton>
              <Skeleton
                isLoaded={!homeBalanceLoading}
                className="w-full  h-full py-2"
              >
                <Card className="w-full  h-full py-2">
                  <div className="md:w-30 max-h-fit text-[#F27D00] flex flex-col gap-2 px-auto py-auto h-full">
                    <div className="flex justify-center items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.562"
                        height="18.562"
                        viewBox="0 0 18.562 18.562"
                      >
                        <g
                          id="Groupe_18369"
                          data-name="Groupe 18369"
                          transform="translate(-442.719 -579.999)"
                        >
                          <path
                            id="Tracé_562"
                            data-name="Tracé 562"
                            d="M4.989,9.979A4.989,4.989,0,1,1,9.979,4.989,4.995,4.995,0,0,1,4.989,9.979Zm0-8.911A3.922,3.922,0,1,0,8.911,4.989,3.926,3.926,0,0,0,4.989,1.068Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 456.812, 584.119)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_563"
                            data-name="Tracé 563"
                            d="M.534,2.975A.534.534,0,0,1,0,2.441V.534a.534.534,0,0,1,1.068,0V2.441A.534.534,0,0,1,.534,2.975Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 461.244, 588.422)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_564"
                            data-name="Tracé 564"
                            d="M1.488,2.719a.534.534,0,0,1-.463-.267L.072.8A.534.534,0,0,1,1,.267L1.95,1.918a.534.534,0,0,1-.462.8Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 459.922, 584.099)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_565"
                            data-name="Tracé 565"
                            d="M2.185,2.021a.532.532,0,0,1-.266-.071L.267,1A.534.534,0,0,1,.8.072l1.651.954a.534.534,0,0,1-.267,1Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 456.615, 581.016)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_566"
                            data-name="Tracé 566"
                            d="M2.441,1.068H.534A.534.534,0,0,1,.534,0H2.441a.534.534,0,0,1,0,1.068Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 452.21, 579.999)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_567"
                            data-name="Tracé 567"
                            d="M.534,2.021a.534.534,0,0,1-.267-1L1.918.072A.534.534,0,1,1,2.452,1L.8,1.95a.532.532,0,0,1-.266.071Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 448.84, 581.287)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_568"
                            data-name="Tracé 568"
                            d="M.533,2.719a.534.534,0,0,1-.462-.8L1.025.267A.534.534,0,0,1,1.95.8L1,2.452a.534.534,0,0,1-.463.267Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 446.454, 584.569)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_569"
                            data-name="Tracé 569"
                            d="M.534,2.975A.534.534,0,0,1,0,2.441V.534a.534.534,0,0,1,1.068,0V2.441A.534.534,0,0,1,.534,2.975Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 445.692, 588.965)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_570"
                            data-name="Tracé 570"
                            d="M1.488,2.719a.534.534,0,0,1-.463-.267L.072.8A.534.534,0,1,1,1,.267L1.95,1.918a.534.534,0,0,1-.462.8Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 446.725, 592.345)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_571"
                            data-name="Tracé 571"
                            d="M2.185,2.021a.531.531,0,0,1-.266-.071L.267,1A.534.534,0,0,1,.8.072l1.651.954a.534.534,0,0,1-.267,1Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 449.31, 594.755)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_572"
                            data-name="Tracé 572"
                            d="M2.441,1.068H.534A.534.534,0,0,1,.534,0H2.441a.534.534,0,1,1,0,1.068Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 452.753, 595.55)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_573"
                            data-name="Tracé 573"
                            d="M.534,2.021a.534.534,0,0,1-.267-1L1.918.072A.534.534,0,0,1,2.452,1L.8,1.95A.531.531,0,0,1,.534,2.021Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 457.086, 594.484)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_574"
                            data-name="Tracé 574"
                            d="M.533,2.719a.534.534,0,0,1-.462-.8L1.025.267A.534.534,0,1,1,1.95.8L1,2.452A.534.534,0,0,1,.533,2.719Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 460.194, 591.874)"
                            fill="#F27D00"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="flex flex-row text-center justify-center items-center font-semibold">
                      Production solaire potentielle
                    </div>
                    <div className="flex justify-center items-center bg-[#F27D00] rounded-[20px] text-white font-bold max-w-fit px-2 md:px-4 mx-auto">
                      {homeBalanceData &&
                        (
                          homeBalanceData[period]["prod" + unit][
                            "solar"
                          ] as number
                        ).toFixed(1)}{" "}
                      {displayUnit(unit, symbolMap)}
                    </div>
                  </div>
                </Card>
              </Skeleton>
              <Skeleton
                isLoaded={!homeBalanceLoading}
                className="w-full  h-full py-2"
              >
                <Card className="w-full  py-2 h-full">
                  <div className="md:w-30 max-h-fit text-[#42d828] flex flex-col gap-2 px-auto py-auto h-full">
                    <div className="flex justify-center items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9.333"
                        height="19.999"
                        viewBox="0 0 9.333 19.999"
                      >
                        <g
                          id="Groupe_18367"
                          data-name="Groupe 18367"
                          transform="translate(-260.667 -636.001)"
                        >
                          <g
                            id="Groupe_18281"
                            data-name="Groupe 18281"
                            transform="translate(260.667 655.999) rotate(-90)"
                          >
                            <g
                              id="Icon_ionic-ios-battery-charging"
                              data-name="Icon ionic-ios-battery-charging"
                            >
                              <path
                                id="Tracé_21330"
                                data-name="Tracé 21330"
                                d="M15.832,0H2A2.006,2.006,0,0,0,0,2V7.333a2.006,2.006,0,0,0,2,2H15.832a2.006,2.006,0,0,0,2-2V2A2.006,2.006,0,0,0,15.832,0Zm.833,7.333a.836.836,0,0,1-.833.833H2a.836.836,0,0,1-.833-.833V2A.836.836,0,0,1,2,1.167H15.832A.836.836,0,0,1,16.666,2Z"
                                fill="#42d828"
                              />
                              <path
                                id="Tracé_21331"
                                data-name="Tracé 21331"
                                d="M0,0V4.283c.667,0,1.333-1.154,1.333-2.142S.667,0,0,0Z"
                                transform="translate(18.666 2.525)"
                                fill="#42d828"
                              />
                            </g>
                            <path
                              id="Tracé_21333"
                              data-name="Tracé 21333"
                              d="M2.992,0,0,3.893H1.658L1.2,6.761,4.287,2.636H2.463Z"
                              transform="translate(14.362 2.523) rotate(90)"
                              fill="#42d828"
                            />
                          </g>
                          <path
                            id="Polygone_2"
                            data-name="Polygone 2"
                            d="M1.5,0,3,3H0Z"
                            transform="translate(264 650)"
                            fill="#42d828"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="flex flex-row text-center justify-center items-center font-semibold">
                      Capacité de charge batterie
                    </div>
                    <div className="flex justify-center items-center bg-[#42d828] rounded-[20px] text-white font-bold max-w-fit px-2 md:px-4 mx-auto">
                      {homeBalanceData &&
                        (
                          homeBalanceData[period]["prod" + unit][
                            "battery"
                          ] as number
                        ).toFixed(1)}{" "}
                      {displayUnit(unit, symbolMap)}
                    </div>
                  </div>
                </Card>
              </Skeleton>
            </div>
          )}

          {/**desktop  */}
          <div className="hidden md:flex flex-col justify-between items-center gap-4 px-4 py-2">
            <div className=" w-full flex flex-row gap-4 ">
              <Card
                className=" h-full w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon</div>
                    <div className="font-semibold text-center py-2">
                      Meilleur période pour brancher vos appareils
                    </div>
                    <div className="font-extrabold">13h00 &gt; 14h00</div>
                  </div>
                }
              />
              <Card
                className="h-full w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon </div>
                    <div className="font-semibold text-center py-2">
                      Plus mauvaise période pour brancher vos appareils
                    </div>
                    <div className="font-extrabold">18h00 &gt; 19h00</div>
                  </div>
                }
              />
            </div>
            <div className="w-full">
              <Card
                className="h-full w-full"
                children={
                  <div className=" h-full py-4 flex flex-row w-full justify-between gap-4 items-center pl-2">
                    <div className="px-2   w-full">
                      <div className=" px-2 border-l-4 border-l-green-500 w-full h-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#42d828"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#42d828"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold  py-2 uppercase">
                          Vos heures creuses
                        </div>
                        <div className="font-extrabold">12h00 &gt; 13h00</div>
                        <div className="font-extrabold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                    <div className="px-2   w-full">
                      <div className=" px-2 border-l-4 border-l-red-500 w-full h-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#e01d0b"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#e01d0b"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold py-2 uppercase">
                          Meteo energie
                        </div>
                        <div className="font-extrabold">12h00 &gt; 13h00</div>
                        <div className="font-extrabold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          <div className="container-weather__left">
            <WeatherDetails />
          </div>
          {/*** version mobile */}
          <div className="block md:hidden flex flex-row justify-between gap-2 px-4 py-2 max-w-sm h-[400px]  ">
            <div className=" w-1/2 h-full flex flex-col gap-4 ">
              <Card
                className="h-1/2 w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon</div>
                    <div className="font-semibold text-center py-2">
                      Meilleur période pour brancher vos appareils
                    </div>
                    <div className="font-bold">13h00 &gt; 14h00</div>
                  </div>
                }
              />
              <Card
                className="h-1/2 w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon</div>
                    <div className="font-semibold text-center py-2">
                      Plus mauvaise période pour brancher vos appareils
                    </div>
                    <div className="font-bold">18h00 &gt; 19h00</div>
                  </div>
                }
              />
            </div>
            <div className="w-1/2 h-full">
              <Card
                className="h-full w-full"
                children={
                  <div className=" h-full py-4 flex flex-col w-full justify-between gap-4 items-center pl-2">
                    <div className="px-2 h-1/2 w-full">
                      <div className=" px-2 border-l-4 border-l-green-500 w-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center  flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#42d828"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#42d828"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold  py-2 uppercase">
                          Vos heures creuses
                        </div>
                        <div className="font-bold">12h00 &gt; 13h00</div>
                        <div className="font-bold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                    <div className="px-2 h-1/2 w-full">
                      <div className=" px-2 border-l-4 border-l-red-500 w-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center  flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#e01d0b"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#e01d0b"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold py-2 uppercase">
                          Meteo energie
                        </div>
                        <div className="font-bold">12h00 &gt; 13h00</div>
                        <div className="font-bold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/***Desktop */}

      <div className=" flex flex-col gap-4 md:flex-row md:gap-4 md:justify-between  hidden md:flex max-w-full px-2">
        <div className="container-weather__left">
          <WeatherDetails />
        </div>
        <div className="container-weather__right w-full flex flex-col items-center py-2 mx-auto">
          <Card className=" w-full md:w-3/4 py-2">
            <Switcher />
          </Card>
          {homeBalanceIsError ? (
            <div>Error</div>
          ) : (
            <div className="flex flex-row gap-2 md:gap-4 justify-between py-2">
              <Skeleton
                isLoaded={!homeBalanceLoading}
                className="w-full h-full  py-2"
              >
                <Card className="w-full h-full py-2">
                  <div className="md:w-30  text-[#E02FEF] flex flex-col justify-between gap-2 px-auto py-auto h-full">
                    <div className="flex justify-center items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 11 16.5"
                      >
                        <path
                          id="Tracé_21968"
                          fill="#E02FEF"
                          data-name="Tracé 21968"
                          d="M10.25,6.5H6.671l-.215-2H8.5v.75a.25.25,0,0,0,.5,0v-1A.25.25,0,0,0,8.75,4H6.4L6,.224A.25.25,0,0,0,5.75,0h-1A.25.25,0,0,0,4.5.224L4.1,4H1.75a.25.25,0,0,0-.25.25v1a.25.25,0,0,0,.5,0V4.5H4.044l-.215,2H.25A.25.25,0,0,0,0,6.75v1a.25.25,0,0,0,.5,0V7H3.775l-.75,7H3a.75.75,0,0,0-.75.75v.75H.5a.25.25,0,0,0,0,.5H10a.25.25,0,1,0,0-.5H8.25v-.75A.75.75,0,0,0,7.5,14H7.475l-.75-7H10v.75a.25.25,0,0,0,.5,0v-1a.25.25,0,0,0-.25-.25ZM4.975.5h.55L5.9,4H4.6Zm-.429,4H5.954l.215,2H4.332Zm3.2,10.25v.75h-5v-.75A.25.25,0,0,1,3,14.5H7.5a.25.25,0,0,1,.25.25ZM6.971,14H3.529l.75-7H6.221Z"
                          transform="translate(0.25 0.25)"
                          stroke="#E02FEF"
                          stroke-width="0.5"
                        />
                      </svg>
                    </div>
                    <div className="flex flex-wrap text-center justify-center items-center font-semibold">
                      Surplus Potentiel
                    </div>
                    <div className="flex flex-row justify-center items-center bg-[#E02FEF] rounded-[20px] text-white font-bold max-w-fit px-2 md:px-4 mx-auto">
                      {homeBalanceData &&
                        (
                          homeBalanceData[period]["prod" + unit][
                            "provider"
                          ] as number
                        ).toFixed(1)}{" "}
                      {displayUnit(unit, symbolMap)}
                    </div>
                  </div>
                </Card>
              </Skeleton>
              <Skeleton
                isLoaded={!homeBalanceLoading}
                className="w-full  h-full py-2"
              >
                <Card className="w-full  h-full py-2">
                  <div className="md:w-30 max-h-fit text-[#F27D00] flex flex-col gap-2 px-auto py-auto h-full">
                    <div className="flex justify-center items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.562"
                        height="18.562"
                        viewBox="0 0 18.562 18.562"
                      >
                        <g
                          id="Groupe_18369"
                          data-name="Groupe 18369"
                          transform="translate(-442.719 -579.999)"
                        >
                          <path
                            id="Tracé_562"
                            data-name="Tracé 562"
                            d="M4.989,9.979A4.989,4.989,0,1,1,9.979,4.989,4.995,4.995,0,0,1,4.989,9.979Zm0-8.911A3.922,3.922,0,1,0,8.911,4.989,3.926,3.926,0,0,0,4.989,1.068Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 456.812, 584.119)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_563"
                            data-name="Tracé 563"
                            d="M.534,2.975A.534.534,0,0,1,0,2.441V.534a.534.534,0,0,1,1.068,0V2.441A.534.534,0,0,1,.534,2.975Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 461.244, 588.422)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_564"
                            data-name="Tracé 564"
                            d="M1.488,2.719a.534.534,0,0,1-.463-.267L.072.8A.534.534,0,0,1,1,.267L1.95,1.918a.534.534,0,0,1-.462.8Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 459.922, 584.099)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_565"
                            data-name="Tracé 565"
                            d="M2.185,2.021a.532.532,0,0,1-.266-.071L.267,1A.534.534,0,0,1,.8.072l1.651.954a.534.534,0,0,1-.267,1Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 456.615, 581.016)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_566"
                            data-name="Tracé 566"
                            d="M2.441,1.068H.534A.534.534,0,0,1,.534,0H2.441a.534.534,0,0,1,0,1.068Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 452.21, 579.999)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_567"
                            data-name="Tracé 567"
                            d="M.534,2.021a.534.534,0,0,1-.267-1L1.918.072A.534.534,0,1,1,2.452,1L.8,1.95a.532.532,0,0,1-.266.071Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 448.84, 581.287)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_568"
                            data-name="Tracé 568"
                            d="M.533,2.719a.534.534,0,0,1-.462-.8L1.025.267A.534.534,0,0,1,1.95.8L1,2.452a.534.534,0,0,1-.463.267Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 446.454, 584.569)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_569"
                            data-name="Tracé 569"
                            d="M.534,2.975A.534.534,0,0,1,0,2.441V.534a.534.534,0,0,1,1.068,0V2.441A.534.534,0,0,1,.534,2.975Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 445.692, 588.965)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_570"
                            data-name="Tracé 570"
                            d="M1.488,2.719a.534.534,0,0,1-.463-.267L.072.8A.534.534,0,1,1,1,.267L1.95,1.918a.534.534,0,0,1-.462.8Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 446.725, 592.345)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_571"
                            data-name="Tracé 571"
                            d="M2.185,2.021a.531.531,0,0,1-.266-.071L.267,1A.534.534,0,0,1,.8.072l1.651.954a.534.534,0,0,1-.267,1Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 449.31, 594.755)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_572"
                            data-name="Tracé 572"
                            d="M2.441,1.068H.534A.534.534,0,0,1,.534,0H2.441a.534.534,0,1,1,0,1.068Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 452.753, 595.55)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_573"
                            data-name="Tracé 573"
                            d="M.534,2.021a.534.534,0,0,1-.267-1L1.918.072A.534.534,0,0,1,2.452,1L.8,1.95A.531.531,0,0,1,.534,2.021Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 457.086, 594.484)"
                            fill="#F27D00"
                          />
                          <path
                            id="Tracé_574"
                            data-name="Tracé 574"
                            d="M.533,2.719a.534.534,0,0,1-.462-.8L1.025.267A.534.534,0,1,1,1.95.8L1,2.452A.534.534,0,0,1,.533,2.719Z"
                            transform="matrix(0.035, 0.999, -0.999, 0.035, 460.194, 591.874)"
                            fill="#F27D00"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="flex flex-row text-center justify-center items-center font-semibold">
                      Production solaire potentielle
                    </div>
                    <div className="flex justify-center items-center bg-[#F27D00] rounded-[20px] text-white font-bold max-w-fit px-2 md:px-4 mx-auto">
                      {homeBalanceData &&
                        (
                          homeBalanceData[period]["prod" + unit][
                            "solar"
                          ] as number
                        ).toFixed(1)}{" "}
                      {displayUnit(unit, symbolMap)}
                    </div>
                  </div>
                </Card>
              </Skeleton>
              <Skeleton
                isLoaded={!homeBalanceLoading}
                className="w-full  h-full py-2"
              >
                <Card className="w-full  py-2 h-full">
                  <div className="md:w-30 max-h-fit text-[#42d828] flex flex-col gap-2 px-auto py-auto h-full">
                    <div className="flex justify-center items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9.333"
                        height="19.999"
                        viewBox="0 0 9.333 19.999"
                      >
                        <g
                          id="Groupe_18367"
                          data-name="Groupe 18367"
                          transform="translate(-260.667 -636.001)"
                        >
                          <g
                            id="Groupe_18281"
                            data-name="Groupe 18281"
                            transform="translate(260.667 655.999) rotate(-90)"
                          >
                            <g
                              id="Icon_ionic-ios-battery-charging"
                              data-name="Icon ionic-ios-battery-charging"
                            >
                              <path
                                id="Tracé_21330"
                                data-name="Tracé 21330"
                                d="M15.832,0H2A2.006,2.006,0,0,0,0,2V7.333a2.006,2.006,0,0,0,2,2H15.832a2.006,2.006,0,0,0,2-2V2A2.006,2.006,0,0,0,15.832,0Zm.833,7.333a.836.836,0,0,1-.833.833H2a.836.836,0,0,1-.833-.833V2A.836.836,0,0,1,2,1.167H15.832A.836.836,0,0,1,16.666,2Z"
                                fill="#42d828"
                              />
                              <path
                                id="Tracé_21331"
                                data-name="Tracé 21331"
                                d="M0,0V4.283c.667,0,1.333-1.154,1.333-2.142S.667,0,0,0Z"
                                transform="translate(18.666 2.525)"
                                fill="#42d828"
                              />
                            </g>
                            <path
                              id="Tracé_21333"
                              data-name="Tracé 21333"
                              d="M2.992,0,0,3.893H1.658L1.2,6.761,4.287,2.636H2.463Z"
                              transform="translate(14.362 2.523) rotate(90)"
                              fill="#42d828"
                            />
                          </g>
                          <path
                            id="Polygone_2"
                            data-name="Polygone 2"
                            d="M1.5,0,3,3H0Z"
                            transform="translate(264 650)"
                            fill="#42d828"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="flex flex-row text-center justify-center items-center font-semibold">
                      Capacité de charge batterie
                    </div>
                    <div className="flex justify-center items-center bg-[#42d828] rounded-[20px] text-white font-bold max-w-fit px-2 md:px-4 mx-auto">
                      {homeBalanceData &&
                        (
                          homeBalanceData[period]["prod" + unit][
                            "battery"
                          ] as number
                        ).toFixed(1)}{" "}
                      {displayUnit(unit, symbolMap)}
                    </div>
                  </div>
                </Card>
              </Skeleton>
            </div>
          )}

          {/**desktop  */}
          <div className="hidden md:flex flex-col justify-between items-center gap-4 px-4 py-2">
            <div className=" w-full flex flex-row gap-4 ">
              <Card
                className=" h-full w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon</div>
                    <div className="font-semibold text-center py-2">
                      Meilleur période pour brancher vos appareils
                    </div>
                    <div className="font-extrabold">13h00 &gt; 14h00</div>
                  </div>
                }
              />
              <Card
                className="h-full w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon </div>
                    <div className="font-semibold text-center py-2">
                      Plus mauvaise période pour brancher vos appareils
                    </div>
                    <div className="font-extrabold">18h00 &gt; 19h00</div>
                  </div>
                }
              />
            </div>
            <div className="w-full">
              <Card
                className="h-full w-full"
                children={
                  <div className=" h-full py-4 flex flex-row w-full justify-between gap-4 items-center pl-2">
                    <div className="px-2   w-full">
                      <div className=" px-2 border-l-4 border-l-green-500 w-full h-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#42d828"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#42d828"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold  py-2 uppercase">
                          Vos heures creuses
                        </div>
                        <div className="font-extrabold">12h00 &gt; 13h00</div>
                        <div className="font-extrabold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                    <div className="px-2   w-full">
                      <div className=" px-2 border-l-4 border-l-red-500 w-full h-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#e01d0b"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#e01d0b"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold py-2 uppercase">
                          Meteo energie
                        </div>
                        <div className="font-extrabold">12h00 &gt; 13h00</div>
                        <div className="font-extrabold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          {/*** version mobile */}
          <div className="block md:hidden flex flex-row justify-between gap-2 px-4 py-2 max-w-sm h-[400px]  ">
            <div className=" w-1/2 h-full flex flex-col gap-4 ">
              <Card
                className="h-1/2 w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon</div>
                    <div className="font-semibold text-center py-2">
                      Meilleur période pour brancher vos appareils
                    </div>
                    <div className="font-bold">13h00 &gt; 14h00</div>
                  </div>
                }
              />
              <Card
                className="h-1/2 w-full"
                children={
                  <div className="px-2 h-full py-4 flex flex-col justify-between items-center">
                    <div>Icon</div>
                    <div className="font-semibold text-center py-2">
                      Plus mauvaise période pour brancher vos appareils
                    </div>
                    <div className="font-bold">18h00 &gt; 19h00</div>
                  </div>
                }
              />
            </div>
            <div className="w-1/2 h-full">
              <Card
                className="h-full w-full"
                children={
                  <div className=" h-full py-4 flex flex-col w-full justify-between gap-4 items-center pl-2">
                    <div className="px-2 h-1/2 w-full">
                      <div className=" px-2 border-l-4 border-l-green-500 w-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center  flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#42d828"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#42d828"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold  py-2 uppercase">
                          Vos heures creuses
                        </div>
                        <div className="font-bold">12h00 &gt; 13h00</div>
                        <div className="font-bold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                    <div className="px-2 h-1/2 w-full">
                      <div className=" px-2 border-l-4 border-l-red-500 w-full flex flex-col items-start justify-start">
                        <div className="px-auto w-full text-center  flex flex-row justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 8.273 16.853"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_2957"
                                  data-name="Rectangle 2957"
                                  width="8.273"
                                  height="16.853"
                                  fill="#e01d0b"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Groupe_18365"
                              data-name="Groupe 18365"
                              transform="translate(0 0)"
                            >
                              <g
                                id="Groupe_18278"
                                data-name="Groupe 18278"
                                transform="translate(0 0)"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Tracé_21935"
                                  data-name="Tracé 21935"
                                  d="M8.062,8.684l-7.3,8.042a.412.412,0,0,1-.424.106c-.052,0-.105-.053-.158-.106a.823.823,0,0,1-.106-.794l2.54-5.82L.337,9.425A.461.461,0,0,1,.02,9a.73.73,0,0,1,.159-.635L6.845.166A.377.377,0,0,1,7.427.113a.732.732,0,0,1,.159.741,1.692,1.692,0,0,0-.106.37l-1.9,5.714s.423.159.952.317c.635.212,1.429.423,1.429.423a.461.461,0,0,1,.317.423,1.109,1.109,0,0,1-.211.582"
                                  transform="translate(0 0)"
                                  fill="#e01d0b"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="font-semibold py-2 uppercase">
                          Meteo energie
                        </div>
                        <div className="font-bold">12h00 &gt; 13h00</div>
                        <div className="font-bold">13h00 &gt; 14h00</div>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
