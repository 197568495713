import '../assets/scss/components/InvoicePanel.scss';

interface InvoicePanelProps {
    homeBalanceData: any
}

const InvoicePanel: React.FC<InvoicePanelProps> = ({ homeBalanceData }) => {
    
 
    return homeBalanceData ? (
        <div className="invoice-panel">
            <div className="invoice-panel__head">
                <p className="invoice-panel__head__title">Ma facture énergétique</p>
            </div>
            <div className="invoice-panel__body">
                <div className="invoice-panel__body__content">
                    <p className="invoice-panel__body__content__title">Batterie</p>
                    <p className="invoice-panel__body__content__sum">
                        {homeBalanceData.battery.toFixed(1)} €
                    </p>
                    <p>d'économie</p>
                </div>
                <div className="invoice-panel__body__content">
                    <p className="invoice-panel__body__content__title">Panneaux</p>
                    <p className="invoice-panel__body__content__sum">
                        {homeBalanceData.solar.toFixed(1)} €
                    </p>
                    <p>d'économie</p>
                </div>
                <div className="invoice-panel__body__content">
                    <p className="invoice-panel__body__content__title">Surplus</p>
                    <p className="invoice-panel__body__content__sum">
                        {homeBalanceData.provider.toFixed(1)} €
                    </p>
                    <p>d'économie</p>
                </div>
            </div>
        </div>
    ) : (
        <div>Chargement...</div>
    );
}

export default InvoicePanel;