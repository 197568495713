import { User } from "../services/users.service";
import { RootState } from "../store/config";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

 
export interface AuthStateType {
    isAuthenticated: boolean;
    user: User | null;
}
let initialState: AuthStateType = {
    isAuthenticated:false,
    user: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      loginAction: (state, action: PayloadAction<AuthStateType>) => {
        return  {isAuthenticated:true,user: action.payload.user};
      },
      logoutAction:() => {
        return {
            isAuthenticated :false,
            user:null};
      }
    }
  })

  export const authReducer = authSlice.reducer

  export const getUser = (state: RootState) => state.auth.user
  export const selectAuth = (state: RootState) => state.auth;
  export const { loginAction, logoutAction } = authSlice.actions