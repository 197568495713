import React, {
  MouseEventHandler,
  ReactNode,
  forwardRef,
  useEffect,
} from "react";
import "../assets/scss/components/Switcher.scss";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import moment, { DurationInputArg2, unitOfTime } from "moment";
import { Box, Button, HStack } from "@chakra-ui/react";
import { displayUnit, useSwitcherContext } from "../contexts/SwitcherContext";
import { OptionType, RadioForm } from "./RadioForm";
import { symbolMap } from "../utils/toolbox";
registerLocale("fr", fr);

interface SwitcherProps {
  units?: string[];
  periods?: string[];
  children?: ReactNode;
  withUnits?: boolean;
  withDates?: boolean;
  withToday?: boolean;
  withPeriods?: boolean;
}
export const Switcher: React.FC<SwitcherProps> = ({
  units,
  children,
  periods,
  withUnits = true,
  withDates = true,
  withToday = true,
  withPeriods = true,
}) => {
  const toOptionType = (options: string[]) => {
    return options.map((o) => {
      let optionType: OptionType = {
        value: o,
        viewValue: getPeriodeName(o),
      };
      return optionType;
    });
  };
  const unitsToOptionType = (options: string[]) => {
    return options.map((o) => {
      let optionType: OptionType = {
        value: o,
        viewValue: getUnitName(o),
      };
      return optionType;
    });
  };
  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();

  const [showIconRight, setShowIconRight] = useState(true);
  const nowCheckedStyle = moment(switcherDate).isSame(new Date(), "day")
    ? { bgColor: "#0087ff", color: "white" }
    : {};
  useEffect(() => {
    setShowIconRight(showRightIcon(period, switcherDate));
  }, [period, switcherDate]);
  const showRightIcon = (period: string, switcherDate: Date) => {
    return moment(switcherDate).isBefore(
      new Date(),
      period as unitOfTime.StartOf
    );
  };
  const increment = (e: any) => {
    if (
      moment(switcherDate)
        .add(1, period as DurationInputArg2)
        .isAfter(new Date(), "date")
    ) {
      setSwitcherDate(new Date());
    } else {
      setSwitcherDate((prev) =>
        moment(switcherDate)
          .add(1, period as DurationInputArg2)
          .toDate()
      );
    }
  };
  const decriment = (e: any) => {
    setSwitcherDate((prev) =>
      moment(switcherDate)
        .subtract(1, period as DurationInputArg2)
        .toDate()
    );
  };
  const getPeriodeName = (p: string) => {
    if (p == "day") return "Jour";
    if (p == "week") return "Semaine";
    if (p == "month") return "Mois";
    if (p == "year") return "Année";
    if (p == "now") return "Aujourd'hui";
  };
  const getUnitName = (u: string) => {
    return displayUnit(u, symbolMap);
  };

  const ExampleCustomInput = forwardRef(
    (
      {
        value,
        onClick,
      }: {
        value?: any;
        onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
      },
      ref: any
    ) => (
      <Button
        onClick={onClick}
        ref={ref}
        variant={"unstyled"}
        rightIcon={<CalendarIcon />}
        color={"#0087ff"}
        className="text-md  font-bold"
      >
        {period == "day" && moment(switcherDate).isSame(new Date(), "day")
          ? "Aujourd'hui"
          : period == "week"
          ? `Semaine ${value}`
          : value}
      </Button>
    )
  );
  return (
    <div className="w-full h-full flex flex-col gap-2 md:gap-4 py-2">
      {withDates && (
        <>
          {" "}
          <div className=" flex flex-row space-between">
            <Button className="" onClick={decriment} variant={"unstyled"}>
              <ChevronLeftIcon color={"#0087ff"} boxSize={6} />
            </Button>
            <div className="w-full  text-center py-auto flex justify-center  ">
              <ReactDatePicker
                showYearPicker={period == "year"}
                dateFormat={
                  period == "year"
                    ? "yyyy"
                    : period == "week"
                    ? "I/R"
                    : period == "month"
                    ? "MMMM yyyy"
                    : "dd MMMM yyyy"
                }
                locale={"fr"}
                showWeekNumbers={period == "week"}
                showWeekPicker={period == "week"}
                showMonthYearPicker={period == "month"}
                selected={switcherDate}
                maxDate={new Date()}
                customInput={<ExampleCustomInput />}
                onChange={(e: any) => {
                  setSwitcherDate(e);
                }}
              />
            </div>
            {
              <Button
                className=""
                onClick={increment}
                disabled={!showIconRight}
                opacity={showIconRight ? "initial" : 0.5}
                variant={"unstyled"}
              >
                <ChevronRightIcon color={"#0087ff"} boxSize={6} />
              </Button>
            }
          </div>
          <div className="w-full flex flex-row gap-2">
            {withPeriods && (
              <HStack
                flexDirection={"row"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                width={"full"}
              >
                {withToday && (
                  <Box
                    cursor="pointer"
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="md"
                    borderColor={"#0087ff"}
                    px={"3"}
                    py={"2"}
                    {...nowCheckedStyle}
                    onClick={() => setSwitcherDate(new Date())}
                    className="flex items-center justify-center font-bold text-[#0087ff]"
                  >
                    Aujourd'hui
                  </Box>
                )}
                {
                  <RadioForm
                    className="font-bold text-[#0087ff]"
                    px={"3"}
                    py={"2"}
                    options={
                      periods
                        ? toOptionType(periods)
                        : toOptionType(["day", "week", "month", "year"])
                    }
                    onChange={(e) => setPeriod(e)}
                    value={period}
                    checkedBgColor="#0087ff"
                    borderColor="#0087ff"
                    defaultValue={"day"}
                  />
                }
              </HStack>
            )}
          </div>{" "}
        </>
      )}
      <div className="py-2">{children}</div>
      {withUnits && (
        <div className="w-full flex flex-row gap-2 justify-center">
          <HStack
            flexDirection={"row"}
            flexWrap={"wrap"}
            justifyContent={"space-around"}
            rowGap={8}
            width={"80%"}
            className="border-b-4 border-b-[#0087ff]"
            maxWidth={"300px"}
            height={"full"}
          >
            {(units
              ? unitsToOptionType(units)
              : unitsToOptionType(["Euro", "kWh", "Pct"])
            ).map((u) => {
              return (
                <p
                  className={`px-2 h-full font-bold text-[#0087ff] cursor-pointer ${
                    unit == u.value ? "border-b-4 border-b-[#0087ff]" : ""
                  }`}
                  onClick={(e) => setUnit(u.value)}
                >
                  {u.viewValue}
                </p>
              );
            })}
          </HStack>
        </div>
      )}
    </div>
  );
};
