import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import "../assets/scss/components/Site.scss"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { chooseTopology } from '../reducers/topologyReducer';

interface SiteProps {
    image:string;
    title: string;
    address:string;
    value:ReactNode | string ;
    id:string;
    batteryCapacity?: string
}

const Site: React.FC<SiteProps> = ({ title ,address,image,value,id,batteryCapacity}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    return (
        <Box className="site cursor-pointer  w-full max-w-[80vw] md:max-w-[300px]" onClick={()=>{ 
           // saveTopologyIdInLocalStorage(id)
            dispatch(chooseTopology({id,batteryCapacity  }))
            navigate("/my-installation")}}>
            <div className = ""><img className="aspect-square" src={image}   width={"full"} height={"full"} /> </div>
            <div className="site__title">
                {title}
            </div>
            <div className="site__address">{address}</div>
            <div className="site__value">{value}</div>
        </Box>
    );
}

export default Site;
