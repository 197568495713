import React from "react";
import "../assets/scss/pages/InstallationDetailsPage.scss";
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Heading,
  Button,
} from "@chakra-ui/react";
import { RadioForm } from "../components/RadioForm";
import { Select } from "@chakra-ui/react";
export const InstallationDetailsPage: React.FC = () => {
  const saveUpdates = () => {};
  const cancel = () => {};
  return (
    <Box padding={"10px"} w={{ base: "100%", md: "50%" }} className="pb-36">
      <Box textAlign={{ base: "center", md: "start" }} margin={"5px"}>
        <Heading>Tarification</Heading>
      </Box>
      <Box>
        <FormControl className="control">
          <FormLabel className="label">Mon abonnement</FormLabel>
          <RadioForm value={""} options={[{value : "HP/HC",viewValue : "HP/HC" }, {value : "Base",viewValue : "Base" } ,{value : "Tempo",viewValue : "Tempo" }]} onChange={(v)=>{ }}></RadioForm>
        </FormControl>
        <FormControl className="control">
          <FormLabel className="label">Tarif HP</FormLabel>
          <Input type="text" name="tarifHP" />
        </FormControl>
        <FormControl className="control">
          <FormLabel className="label">Tarif HC</FormLabel>
          <Input type="text" name="tarifHC" />
        </FormControl>
      </Box>

      <Box textAlign={{ base: "center", md: "start" }} margin={"5px"}>
        <Heading>Installation</Heading>
      </Box>
      <Box>
        <FormControl className="control">
          <FormLabel className="label">Type d'installation</FormLabel>
          <RadioForm value={""} options={[{value : "PV",viewValue : "PV"}, {value : "PV+Batterie",viewValue : "PV+Batterie"} ]} onChange={(v)=>{ }}></RadioForm>
        </FormControl>
        <FormControl className="control">
          <FormLabel className="label">Taille PV</FormLabel>
          <Input type="text" name="taillePV" />
        </FormControl>
        <FormControl className="control">
          <FormLabel className="label">Batterie</FormLabel>
          <Input type="text" name="batterie" />
        </FormControl>
      </Box>
      <Box textAlign={{ base: "center", md: "start" }} margin={"5px"}>
        <Heading>Ma maison</Heading>
      </Box>
      <Box>
        <FormControl className="control">
          <FormLabel className="label">Type de chauffage</FormLabel>
          <Select placeholder="----------">
            <option value="option1">Electrique</option>
            <option value="option2">Gaz</option>
            <option value="option3">Fioul</option>
            <option value="option3">PAC</option>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl className="control">
          <FormLabel className="label">Taille Maison</FormLabel>
          <Select placeholder="----------">
            <option value="option1">100m2</option> 
          </Select>
        </FormControl>
      </Box>
      <Box className="flex flex-row">
        <Button
          colorScheme="#003265"
          className="rounded-md md:w-1/3 mx-auto"
          size={"lg"}
          onClick={saveUpdates}
          style={{
            backgroundColor: "#003265",
            color: "white",
            borderColor: "teal.600",
          }}
          _hover={{
            backgroundColor: "#003265",
            color: "white",
            borderColor: "teal.600",
          }}
        >
          Enregistrer
        </Button>
        <Button
          className="rounded-md md:w-1/3 mx-auto"
          size={"lg"}
          onClick={cancel}
          colorScheme="gray"
        >
          Annuler
        </Button>
      </Box>
    </Box>
  );
};
