import { ROLES, getUserRoles } from "../utils/toolbox"; 
import { useEffect, useState } from "react";
import { selectAuth } from "../reducers/authReducer";
import { store } from "../store/config"; 
 const  useGetMembership = ()=>{ 
    const [membership,setMembership] = useState<string []>(["UNKNOWN"])
    const auth = selectAuth(store.getState())
    useEffect(()=>{
        if(auth){
            if(getUserRoles(auth.user?.token || "").some(r=>r == ROLES.WEBAPP_USER_SOALIS)  && getUserRoles(auth.user?.token || "").some(r=>r == ROLES.WEBAPP_USER_FRACTAL) ) setMembership(_prev=>[ "SOALIS","FRACTAL"])   ;
            else if(getUserRoles(auth.user?.token || "").some(r=>r == ROLES.WEBAPP_USER_SOALIS)) setMembership(["SOALIS"]) 
            else if(getUserRoles(auth.user?.token || "").some(r=>r == ROLES.WEBAPP_USER_FRACTAL)) setMembership(["FRACTAL"]) 
        }
    },[])
    return  membership
}  
export default useGetMembership