import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
  Select,
  FormLabel,
  Input,
  Button,
  Checkbox,
  FormHelperText,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import EmsAlert from "./EmsAlert";
import { Field } from "../pages/PageAdmin";
import { CustumSelect } from "./CustumSelect";
import { ResponseType, crudApi } from "../services/crud.service";
import { PATH_PREFIX_FLUXTOPOLOGY, URIs } from "../utils/toolbox";
import { EnumSelect } from "./EnumSelect";

interface ModalUpdateProps {
  setShowCreateNewElement: (x: boolean) => void;
  uri: string;
  title: string;
  showCreateNewElement: boolean;
  obj: string;
  onCreateOk: () => void;
}

export const ModalCreate: React.FC<ModalUpdateProps> = ({
  setShowCreateNewElement,
  uri,
  showCreateNewElement,
  title,
  obj,
  onCreateOk,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [dataToCreate, setDataToCreate] = useState<ResponseType>();
  const [createMessage, setCreateMessage] = useState<ReactNode | undefined>(
    undefined
  );

  const { useGetByCritereMutation, useCreateMutation } = crudApi;

  const [create, createResponse] = useCreateMutation();
  const [columns, setColumns] = useState<string[]>([]);
  const [fields, setFields] = useState<ResponseType[]>([]);
  const [getByCritere, getByCritereResponse] = useGetByCritereMutation();

  const createDataSubmit = () => {
    setCreateMessage(undefined);
    create({ uri: uri, body: dataToCreate })
      .then((res) => {
        if ("error" in res && res.error) {
          setCreateMessage(
            <EmsAlert
              description={"Erreur lors de la création."}
              status="error"
              title=""
            />
          );
        } else if ("data" in res && res.data) {
          setCreateMessage(
            <EmsAlert
              description={"Création effectuée avec succès."}
              status="success"
              title=""
            />
          );
        }
      })
      .catch((err) => {
        setCreateMessage(
          <EmsAlert
            description={"Erreur lors de la création."}
            status="error"
            title=""
          />
        );
      });
  };
  useEffect(() => {
    getByCritere({
      uri: PATH_PREFIX_FLUXTOPOLOGY + "/" + URIs.ParametrageURI,
      body: { className: obj },
    })
      .unwrap()
      .then((res) => {
        let fields: Field[] = res as Field[];
        let cols: string[] = [];
        if (fields) {
          fields.map((field: Field, index: number, array: Field[]) => {
            if (field.show) {
              cols.push(field.name);
            }
          });
          setColumns(cols);
          setFields(fields);
        }
      });
  }, [uri, obj]);
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={showCreateNewElement}
      onClose={() => {
        setShowCreateNewElement(false);
        setCreateMessage(undefined);
        onClose();
        onCreateOk();
      }}
    >
      <ModalOverlay />
      <ModalContent zIndex={120}>
        <ModalHeader>
          {" "}
          {
            <div className="flex flex-col  ">
              <h1 className="font-extrabold text-xl m-2">
                Création du {title}
              </h1>
              {createMessage && <div>{createMessage}</div>}
            </div>
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} className="overflow-y-scroll max-h-[500px]">
          <form className="flex flex-col  pl-2 gap-4 mt-4">
            {fields &&
              fields.map((field) => {
                let newField: Field = field as Field;
                if (newField.name && newField.name.toLocaleLowerCase() == "id")
                  return <></>;
                return (
                  <FormControl className="flex flex-row items-center">
                    <FormLabel className="min-w-fit">
                      {newField.viewName}
                    </FormLabel>
                    {newField.typeField == "ENUM" ? (
                      <EnumSelect
                        name={
                          newField.value
                            ? newField.value.toLocaleLowerCase()
                            : ""
                        }
                        defaultValue={
                          dataToCreate && newField.name in dataToCreate
                            ? (dataToCreate[newField.name] as string)
                            : ""
                        }
                        onChange={(e) =>
                          setDataToCreate((prev) => ({
                            ...prev,
                            [newField.name]: e.target.value,
                          }))
                        }
                        selected={undefined}
                      />
                    ) : newField.typeField == "CLASS" ? (
                      <CustumSelect
                        obj={newField.value}
                        onChange={(e) =>
                          setDataToCreate((prev) => {
                            return {
                              ...prev,
                              [newField.name]: { id: e.target.value },
                            };
                          })
                        }
                      ></CustumSelect>
                    ) : newField.typeField == "BOOLEAN" ? (
                      <Checkbox
                        size="lg"
                        name={newField.name}
                        isChecked={
                          dataToCreate && newField.name in dataToCreate
                            ? (dataToCreate[newField.name] as boolean)
                            : false
                        }
                        onChange={(e) =>
                          setDataToCreate((prev) => ({
                            ...prev,
                            [newField.name]: e.target.checked,
                          }))
                        }
                      ></Checkbox>
                    ) : newField.typeField == "DATE" ? (
                      <div className="flex flex-col w-full">
                        <FormControl className="flex flex-row items-center">
                          <FormLabel className="min-w-fit">
                            <h1 className="min-w-fit font-extrabold flex flex-row items-center content-center">
                              {newField.viewName}
                              <span className="text-red-500 my-auto pl-2">
                                *
                              </span>
                            </h1>
                          </FormLabel>
                          <div className="flex flex-col w-full">
                            <Input
                              type={"datetime-local"}
                              name={newField.name}
                              required
                              onChange={(e) =>
                                setDataToCreate((prev) => ({
                                  ...prev,
                                  [newField.name]: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </FormControl>
                      </div>
                    ) : (
                      <>
                        <Input
                          hidden={"ID" == newField.name.toUpperCase()}
                          type="text"
                          name={newField.name}
                          onChange={(e) =>
                            setDataToCreate((prev) => ({
                              ...prev,
                              [newField.name]: e.target.value,
                            }))
                          }
                        />
                        {newField.name == "type" && (
                          <FormHelperText>
                            ENUM,DOUBLE_ARRAY, STRING, DOUBLE, INTEGER, LONG,
                            FLOAT, BOOLEAN
                          </FormHelperText>
                        )}
                        {newField.name == "typeValues" &&
                          dataToCreate &&
                          dataToCreate["type"] &&
                          dataToCreate["type"] == "DOUBLE_ARRAY" && (
                            <FormHelperText>
                              Donner des valeurs sous format [a,b] (exemple :
                              [3,4]).{" "}
                            </FormHelperText>
                          )}

                        {newField.name == "typeValues" &&
                          dataToCreate &&
                          dataToCreate["type"] &&
                          dataToCreate["type"] == "ENUM" && (
                            <FormHelperText>
                              Donner des valeurs séparées par ; (exemple :
                              1;2;3).{" "}
                            </FormHelperText>
                          )}
                      </>
                    )}
                  </FormControl>
                );
              })}

            <Button
              colorScheme="twitter"
              className="rounded-md md:w-1/3 mx-auto my-2"
              size={"lg"}
              onClick={createDataSubmit}
            >
              Créer
            </Button>
          </form>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
