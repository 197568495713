import React, { ReactNode, useEffect, useState } from "react";
import AppBar from "../components/AppBar";
import { AppBarTop } from "../components/AppBarTop";
import { Hide } from "@chakra-ui/react";

interface MainLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, className }) => {
  /* const [SESSION_EXPIRED_ERROR, setSESSION_EXPIRED_ERROR] = useState<
    ReactNode | undefined
  >(undefined);*/
  //const localStorageExpiredSessionError = localStorage.getItem(REFRESH_TOKEN_ERROR)
  /* useEffect(() => {
    setSESSION_EXPIRED_ERROR(
      localStorageExpiredSessionError ? (
        <EmsAlert
          status="error"
          title={getLoadingDataErrorByStatus(0).titre}
          description={getLoadingDataErrorByStatus(0).message}
        />
      ) : (
        <></>
      )
    );
  }, [localStorageExpiredSessionError]);*/
  return (
    <>
      <div
        className="w-full h-full  flex flex-col  overflow-hidden"
        style={{ minHeight: "100vh" }}
      >
        <div className="h-full overflow-auto">{children}</div>
        <div className="max-h-fit mt-auto fixed bottom-0 w-full">
          <AppBar />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
