/**
 * Plugin customisé pour afficher un texte au centre des graphiques en doughnut via ChartJS.
 */
export const centerTextPlugin = {
  id: 'centerTextPlugin',
  beforeDraw: (chart) => {
    let width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

    ctx.restore();
    let fontSize = (height / 114).toFixed(2);
    ctx.font = fontSize + "em 'Titillium Web Regular'";
    ctx.textBaseline = "middle";

    // Utilise l'option centerText comme texte à afficher.
    const pluginOptions = chart.options.plugins?.centerTextPlugin;
    let text = pluginOptions?.text || "",
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;

    // Fond coloré
    let backgroundColor = pluginOptions?.backgroundColor || "#dadada"; // Ajustez cette couleur comme vous le souhaitez
    const radius = Math.min(width, height) / 2 * 0.4; // Le rayon du cercle est défini comme 70% du rayon du doughnut
    ctx.beginPath();
    ctx.arc(width / 2, height / 2, radius, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fillStyle = backgroundColor;
    ctx.fill();

    // Enregistre l'état actuel du contexte avant d'appliquer l'ombre
    ctx.save();

    // Ajout de l'ombre
    const shadowColor = pluginOptions?.shadowColor || 'rgba(0, 0, 0, 0.5)'; // Utilise l'option shadowColor ou la valeur par défaut
    ctx.shadowColor = shadowColor
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0; 

    // Couleur du texte
    const textColor = pluginOptions?.textColor || "#FFFFFF"
    ctx.fillStyle = textColor;
    ctx.fillText(text, textX, textY);

    // Restaure l'état du contexte à l'état sauvegardé, supprimant ainsi l'effet d'ombre pour les dessins ultérieurs
    ctx.restore();
  }
};
