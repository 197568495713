import { Doughnut } from 'react-chartjs-2';
import { useEffect, useState } from 'react';


interface DoughnutGraphProps {
    backgroundColor?: Array<String>;
    labels?: Array<String>;
    datas?: Array<Number>;
    formatter :  (value: number, context: any)=>any
}

export const DoughnutGraph: React.FC<DoughnutGraphProps> = ({ backgroundColor = [], labels = [], datas = [],formatter}) => {
    
const plugins: any = {
    tooltip: {
        enabled: true,
    },
    datalabels: {
        enabled: true
    }
}

const options = {
    cutout: "40%",
    hoverOffset: 4,
    borderWidth: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
          },
        datalabels: {
            color: 'white',
            // Permet d'éviter que les labels se chevauchent si les segments sont trop petits.
            display: 'auto',
            formatter 
        },
    }
}
    const [data, setData] = useState<any | null>(null);



    useEffect(() => {
            setData({
                labels: labels,
                datasets: [
                    {                        
                        data: datas,
                        backgroundColor: backgroundColor
                    },
                ],
            });
    }, [labels,datas ]);

    return data ? (
        <Doughnut 
            data={data} 
            plugins={plugins} 
            options={options}
            className="mb-5 max-h-[130px]"
        />
    ) : (
        <div>Chargement...</div>
    );
};
