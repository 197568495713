import { Card, Icon, Skeleton } from "@chakra-ui/react";
import { COLORS } from "../utils/toolbox";
import { ReactNode } from "react";
import { DPEGES } from "./DPEGES";

interface EcoCoachingIndicatorProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: ReactNode;
  value: string;
  color: COLORS;
  isLoaded: boolean;
}
export const EcoCoachingIndicator: React.FC<EcoCoachingIndicatorProps> = ({
  icon,
  color,
  value,
  text,
  isLoaded,
}) => {
  return (
    <Skeleton isLoaded={isLoaded} className="w-full h-full  py-2 max-w-fit ">
      <Card className="w-full h-full p-4">
        <div
          className={`md:w-30  flex flex-col justify-between gap-2 px-auto py-auto h-full`}
          style={{
            color: color,
          }}
        >
          <div className="flex justify-center items-center ">
            <Icon as={icon} boxSize={16} />
          </div>
          <div className="flex flex-wrap text-center justify-center items-center font-semibold px-2">
            {text}
          </div>
          <div
            className={`flex flex-row justify-center items-center rounded-lg text-white font-bold max-w-fit py-3 px-4 mx-auto`}
            style={{
              backgroundColor: color,
            }}
          >
            {value}
          </div>
        </div>
      </Card>
    </Skeleton>
  );
};
