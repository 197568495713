import { FormControl } from "@chakra-ui/form-control";
import React, { useState } from "react";
import { RadioForm } from "../components/RadioForm";
import { ROLES, getUserRoles } from "../utils/toolbox";
import { ParametragePage } from "./ParametragePage";
import { TopologyAdminPage } from "./TopologyAdminPage";
import { EnergyProviderAdminPage } from "./EnergyProviderAdminPage";
import { selectAuth } from "../reducers/authReducer";
import { store } from "../store/config";

export type Field = {
  id?: number;
  name: string;
  viewName: string;
  className: string;
  typeField: string;
  show: number;
  value: string;
  [key: string]: string | number | undefined;
};
interface EmsType {}

export const PageAdmin: React.FC = () => {
  const [choice, setChoice] = useState("topologies");

  const auth = selectAuth(store.getState())
  return (
    <div>
      <div className="flex flex-row flex-start gap-4 my-2">
        <FormControl className="control">
          <RadioForm
          value={choice}
            options={
              getUserRoles(auth?.user?.token || "").some(r=>r==ROLES.WEBAPP_ADMIN_IFPEN)
                ? //TODO : eneleve parametrage dynamically
                  [
                    {
                      value: "parametrage",
                      viewValue: "Paramétrage des champs",
                    },
                    {
                      value: "topologies",
                      viewValue: " Gestion des topologies",
                    },
                    {
                      value: "energyProviders",
                      viewValue: " Gestion des fournisseurs d'énergie",
                    },
                  ]
                : [
                    {
                      value: "topologies",
                      viewValue: " Gestion des topologies",
                    },
                    {
                      value: "energyProviders",
                      viewValue: " Gestion des fournisseurs d'énergie",
                    },
                  ]
            }
            onChange={(v) => {
              setChoice(v);
            }}
            defaultValue="topologies"
          ></RadioForm>
        </FormControl>
      </div>
      <div className="flex flex-col gap-4">
        {choice == "parametrage" ? (
          <ParametragePage></ParametragePage>
        ) : choice == "topologies" ? (
          <TopologyAdminPage />
        ) : (
          <EnergyProviderAdminPage />
        )}
      </div>
    </div>
  );
};
