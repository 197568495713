import { useSwitcherContext } from "../contexts/SwitcherContext";
import "../assets/scss/components/BatteryGraphs.scss";
import { COLORS, TimeLabels, getFormatedDatetime } from "../utils/toolbox";
import { useNavigate } from "react-router-dom";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { memo, useEffect } from "react";
import { useLazyGetMonitoringActualAndForecastDataQuery } from "../services/ems.service";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down-white.svg";
import { Switcher } from "./Switcher";
import LabeledBarChart from "./LabeledBarChart";
import moment from "moment";
import Panel from "./Panel";
import { Skeleton } from "@chakra-ui/react";
import EmsAlert from "./EmsAlert";
interface BatteryGraphsProps {}
export const BatteryGraphs: React.FC<BatteryGraphsProps> = memo(({}) => {
  //pBatGlobal
  const labelsBatt: TimeLabels[] = [];
  const pBatGlobalCharge: any[] = [];
  const pBatGlobalDeCharge: any[] = [];

  //socBatGlobal

  const labelsSocBatGlobal: TimeLabels[] = [];
  const socBatGlobal: any[] = [];

  const { unit, setUnit, period,setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  const navigate = useNavigate();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
  }, [selectedTopoId]);
  //const monitoringResponse =  useGetMonitoringDataQuery(switcherDate)
  const [
    getMonitoringWithForecast,
    {
      data: monitoringWithForecast,
      isError: monitoringWithForecastIsError,
      isLoading: monitoringWithForecastIsLoading,
    },
  ] = useLazyGetMonitoringActualAndForecastDataQuery();

  const fetchData = async () => {
    getMonitoringWithForecast({
      date: moment(switcherDate).toDate(),
      topologyId: selectedTopoId,
    });
  };
  useEffect(() => {
    setSwitcherDate(new Date());
    setPeriod("day");
    setUnit("kWh");
  }, [selectedTopoId]);
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    fetchData();
  }, [switcherDate, selectedTopoId]);
  //Actual
  if (
    monitoringWithForecast &&
    monitoringWithForecast.outputDTOActual &&
    monitoringWithForecast.outputDTOActual[period]
  ) {
    const actual = monitoringWithForecast.outputDTOActual;
    //pBatGlobal
    actual[period]["pBatGlobal" + unit].forEach((item: any) => {
      getFormatedDatetime(item, period, labelsBatt);
      if (item.energy < 0) {
        pBatGlobalCharge.push(0);
      } else {
        pBatGlobalCharge.push(item.energy.toFixed(1));
      }
    });
    actual[period]["pBatGlobal" + unit].forEach((item: any) => {
      if (item.energy > 0) {
        pBatGlobalDeCharge.push(0);
      } else {
        pBatGlobalDeCharge.push(item.energy.toFixed(1));
      }
    });
    //socBatGlobal
    actual[period]["socBatGlobal" + unit].forEach((item: any) => {
      getFormatedDatetime(item, period, labelsSocBatGlobal);
      socBatGlobal.push((item.energy * 100).toFixed(1));
    });
  }

  const graphBatt = {
    labels: labelsBatt,
    datasets: [
      {
        key: 1,
        label: "Charge batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: pBatGlobalCharge,
        bgColor: COLORS.BATTERYUP,
        backgroundColor: "rgb(66, 216, 40)",
        borderWidth: 0,
        isForecast: false,
      },
      {
        key: 2,
        label: "Décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pBatGlobalDeCharge,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119)",
        borderWidth: 0,
        isForecast: false,
      },
    ],
  };

  const graphSocBatGlobal = {
    labels: labelsSocBatGlobal,
    datasets: [
      {
        key: 3,
        label: "Etat de charge de la batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: socBatGlobal,
        bgColor: COLORS.EMS_BLUE_SEMI_LIGHT,
        backgroundColor: COLORS.EMS_BLUE_SEMI_LIGHT,
        borderWidth: 0,
        isForecast: false,
      },
    ],
  };

  return (
    <div className="flex flex-col gap-4 p-2">
      {monitoringWithForecastIsError ? (
        <EmsAlert
          status="error"
          title={"Erreur lors de la récupération des données"}
          description={"Error"}
        ></EmsAlert>
      ) : monitoringWithForecastIsLoading ? (
        <div>
          <Panel
            className="flex-grow"
            title={
              <div>
                <div className="">Ma production globale d'énergie</div>
              </div>
            }
          >
            <Skeleton className="h-full ">
              <div className=" w-full h-full"></div>
            </Skeleton>
          </Panel>
        </div>
      ) : (
        <>
          <Panel
            title={
              <div className="text-center text-[#0488F9] md:mt-2 text-xl md:text-3xl font-extrabold">
                {" "}
                Potentiel de charge{" "}
              </div>
            }
          >
            <Switcher units={["kWh"]} withDates={true} withPeriods={false}>
              <LabeledBarChart
                data={graphSocBatGlobal}
                getDataForLabel={() => {}}
                displayCols
              />
            </Switcher>
          </Panel>
          <Panel
            title={
              <div className="text-center text-[#0488F9] md:mt-2 text-xl md:text-3xl font-extrabold">
                {" "}
                Activites de la batterie{" "}
              </div>
            }
          >
            <Switcher units={["kWh"]}  withDates={true} withPeriods={false}>
              <LabeledBarChart
                data={graphBatt}
                getDataForLabel={() => {}}
                displayCols
              />
            </Switcher>
          </Panel>
        </>
      )}
    </div>
  );
});
