 
import { WeatherItemType } from "../components/WeatherDetails";
import { createApi  } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./ems.service";
const PATH_WEATHER = "weather"
export const weatherApi = createApi({
  reducerPath: "weatherApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({ 
      //weather
      getWeatherItemByTopoAndDate: builder.mutation<WeatherItemType, {date:Date,fluxTopology :{id:number}}>({
        query: (params) => ({ url: `${PATH_WEATHER}/byTopoAndDate`, method: "POST", body: params }),
      }), 
    })
});
 
export const { useGetWeatherItemByTopoAndDateMutation} = weatherApi;
