import { ResponsiveLayout } from "../layouts/ResponsiveLayout";

//legendes images
import { ReactComponent as Sun } from "../assets/icons/ifpen-sun.svg";
import { ReactComponent as Pylon } from "../assets/icons/ifpen-pylon.svg";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down.svg";
import {
  COLORS,
  TimeLabels,
  getFormatedDatetime,
  getLoadingDataErrorByStatus,
} from "../utils/toolbox";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { useNavigate } from "react-router-dom";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { useEffect, useState } from "react";
import {
  useGetAutonomieIndicatorDataQuery,
  useLazyGetManualMonitoringWithFilterDataQuery,
  useLazyGetMonitoringActualAndForecastDataQuery,
} from "../services/ems.service";
import moment from "moment";
import { Skeleton } from "@chakra-ui/react";
import EmsAlert from "../components/EmsAlert";
import { Switcher } from "../components/Switcher";
import Panel from "../components/Panel";
import AutonomieIndicator from "../components/AutonomieIndicator";
import LabeledBarChart from "../components/LabeledBarChart";

interface PageIndicatorProps {}
export const PageIndicator: React.FC<PageIndicatorProps> = ({}) => {
  const labelsProd: TimeLabels[] = [];
  const labelsConsum: TimeLabels[] = [];
  const pProdConsByBat: any[] = [];
  const pProdSentToGrid: any[] = [];
  const pProdConsByConsumers: any[] = [];
  const pConsoFromProd: any[] = [];
  const pConsoFromBat: any[] = [];
  const pConsoFromGrid: any[] = [];

  const pProdConsByBatForecast: any[] = [];
  const pProdSentToGridForecast: any[] = [];
  const pProdConsByConsumersForecast: any[] = [];
  const pConsoFromProdForecast: any[] = [];
  const pConsoFromBatForecast: any[] = [];
  const pConsoFromGridForecast: any[] = [];

  let pProdGlobal: number = 0;
  let pConsoGlobal: number = 0;

  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  const navigate = useNavigate();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
  }, [selectedTopoId]);

  useEffect(() => {
    setSwitcherDate(new Date());
    setUnit("kWh");
    setPeriod("day");
  }, [selectedTopoId]);

  //const monitoringResponse =  useGetMonitoringDataQuery(switcherDate)
  const [
    getMonitoringWithForecast,
    {
      data: monitoringWithForecast,
      isError: monitoringWithForecastIsError,
      isLoading: monitoringWithForecastIsLoading,
    },
  ] = useLazyGetMonitoringActualAndForecastDataQuery();
  const {
    data: autonomieData,
    isError: autonomieIsError,
    error: autonomieError,
    isLoading: autonomieIsLoading,
  } = useGetAutonomieIndicatorDataQuery({
    switcherDate,
    topologyId: selectedTopoId,
  });

  const manualMonitoringResponse =
    useLazyGetManualMonitoringWithFilterDataQuery();
  const [data, setData] = useState<any>(null);
  const fetchData = async () => {
    getMonitoringWithForecast({
      date: moment(switcherDate).toDate(),
      topologyId: selectedTopoId,
    })
      .unwrap()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        if (err.status == 401) {
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    fetchData();
  }, [switcherDate, selectedTopoId]);

  const fetchMonitorungManualData = async (
    startDateTime: Date,
    endDateTime: Date,
    aggregation: string
  ) => {
    const res = await manualMonitoringResponse[0]({
      startDateTime,
      endDateTime,
      aggregation,
      topologyId: selectedTopoId,
    });

    return res.data.data;
  };
  const getDataForLabel = (timelabel: TimeLabels) => {
    const dateLabel = new Date(timelabel.instant);
    dateLabel.setMinutes(
      dateLabel.getMinutes() - dateLabel.getTimezoneOffset()
    );
    switch (period) {
      case "day":
        if (Number(timelabel.label.split("h")[0]) < 4) return;
        let startDateTime = new Date(dateLabel);
        startDateTime.setHours(dateLabel.getHours() - 1);
        let endDateTime = new Date(dateLabel);
        let aggregation = "10m";
        fetchMonitorungManualData(startDateTime, endDateTime, aggregation)
          .then((res) => {
            setData({
              day: res,
            });
          })
          .catch((err) => {
            console.log("err", err);
          });
        break;
      case "week":
        setSwitcherDate(dateLabel);
        setPeriod("day");
        break;
      case "month":
        setSwitcherDate(dateLabel);
        setPeriod("day");
        break;
      case "year":
        setSwitcherDate(dateLabel);
        setPeriod("month");
        break;
      default:
        break;
    }

    return;
  };
  //Actual
  if (data && data.outputDTOActual && data.outputDTOActual[period]) {
    const actual = data.outputDTOActual;
    const filterDayTime = (item: any) =>
      actual[period].aggregation == "1h" ? item.dayTime : true;
    actual[period]["pProdConsByBat" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        getFormatedDatetime(item, period, labelsProd);
        pProdConsByBat.push(item.energy.toFixed(1));
      });

    actual[period]["pProdConsByConsumers" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        pProdConsByConsumers.push(item.energy.toFixed(1));
      });
    actual[period]["pProdGlobal" + "kWh"].forEach((item: any) => {
      pProdGlobal += parseFloat(item.energy.toFixed(1));
    });

    actual[period]["pConsoGlobal" + "kWh"].forEach((item: any) => {
      pConsoGlobal += parseFloat(item.energy.toFixed(1));
    });

    actual[period]["pProdSentToGrid" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        pProdSentToGrid.push(item.energy.toFixed(1));
      });
    //conso
    actual[period]["pConsoFromProd" + unit].forEach((item: any) => {
      getFormatedDatetime(item, period, labelsConsum);
      pConsoFromProd.push(item.energy.toFixed(1));
    });

    actual[period]["pConsoFromGrid" + unit].forEach((item: any) => {
      pConsoFromGrid.push(item.energy.toFixed(1));
    });

    actual[period]["pConsoFromBat" + unit].forEach((item: any) => {
      pConsoFromBat.push(item.energy.toFixed(1));
    });
  }
  //forecast
  if (data && data.outputDTOForeacasted && data.outputDTOForeacasted[period]) {
    const forecasted = data.outputDTOForeacasted;
    const filterDayTime = (item: any) =>
      forecasted[period].aggregation == "1h" ? item.dayTime : true;
    const addItem = (array: any[], item: any) => {
      array.push(item.energy.toFixed(1));
    };
    forecasted[period]["pProdConsByBat" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        getFormatedDatetime(item, period, labelsProd);
        addItem(pProdConsByBatForecast, item);
      });

    forecasted[period]["pProdConsByConsumers" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        addItem(pProdConsByConsumersForecast, item);
      });

    forecasted[period]["pProdSentToGrid" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        addItem(pProdSentToGridForecast, item);
      });
    //conso
    forecasted[period]["pConsoFromProd" + unit].forEach((item: any) => {
      getFormatedDatetime(item, period, labelsConsum);
      addItem(pConsoFromProdForecast, item);
    });

    forecasted[period]["pConsoFromGrid" + unit].forEach((item: any) => {
      addItem(pConsoFromGridForecast, item);
    });

    forecasted[period]["pConsoFromBat" + unit].forEach((item: any) => {
      addItem(pConsoFromBatForecast, item);
    });
  }
  const graphProd = {
    labels: labelsProd,
    datasets: [
      {
        key: 1,
        label: "Autoconsommation directe",
        data: pProdConsByConsumers,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0)",
        borderWidth: 0,
      },
      {
        key: 1,
        label: "Prédiction autoconsommation directe",
        data: pProdConsByConsumersForecast,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0,0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 2,
        label: "Charge batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: pProdConsByBat,
        bgColor: COLORS.BATTERYUP,
        backgroundColor: "rgb(66, 216, 40)",
        borderWidth: 0,
      },
      {
        key: 2,
        label: "Prédiction charge batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: pProdConsByBatForecast,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(66, 216, 40,0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 3,
        label: "Surplus réinjecté dans le réseau",
        image: Pylon,
        darkImage: BatteryDown,
        data: pProdSentToGrid,
        bgColor: COLORS.SURPLUS,
        backgroundColor: "rgb(224, 47, 239)",
        borderWidth: 0,
      },
      {
        key: 3,
        label: "Prédiction surplus réinjecté dans le réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pProdSentToGridForecast,
        bgColor: COLORS.SURPLUS,
        backgroundColor: "rgb(224, 47, 239,0.5)",
        borderWidth: 0,
        isForecast: true,
      },
    ],
  };

  const graphConso = {
    labels: labelsConsum,
    datasets: [
      {
        key: 4,
        label: "Autoconsommation directe",
        data: pConsoFromProd,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0)",
        borderWidth: 0,
      },
      {
        key: 4,
        label: "Prédiction autoconsommation directe",
        data: pConsoFromProdForecast,
        image: Sun,
        darkImage: Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0, 0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 5,
        label: "Décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pConsoFromBat,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119)",
        borderWidth: 0,
      },
      {
        key: 5,
        label: "Prédiction décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pConsoFromBatForecast,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119, 0.5)",
        borderWidth: 0,
        isForecast: true,
      },
      {
        key: 6,
        label: "Consommation réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pConsoFromGrid,
        bgColor: COLORS.CONSO,
        backgroundColor: "rgb(224, 0, 18)",
        borderWidth: 0,
      },
      {
        key: 6,
        label: "Prédiction consommation réseau",
        image: Pylon,
        darkImage: Pylon,
        data: pConsoFromGridForecast,
        bgColor: COLORS.CONSO,
        backgroundColor: "rgb(224, 0, 18, 0.5)",
        borderWidth: 0,
        isForecast: true,
      },
    ],
  };

  return (
    <ResponsiveLayout
      leftSide={
        <Panel title="Mon autonomie" withArrow={false} className="max-w-[500px]">
          <Switcher>
            {autonomieIsError ? (
              <EmsAlert
                status="error"
                title={
                  ("status" in autonomieError
                    ? getLoadingDataErrorByStatus(autonomieError.status).titre
                    : getLoadingDataErrorByStatus(undefined).titre) +
                  " Lors du chargement des données de l'autonomie "
                }
                description={
                  "status" in autonomieError
                    ? getLoadingDataErrorByStatus(autonomieError.status).message
                    : getLoadingDataErrorByStatus(undefined).message
                }
              />
            ) : autonomieIsLoading ? (
              <Skeleton className="h-[550px]">
                <div className=" w-full h-full h-[550px] md:w-[400px] "></div>
              </Skeleton>
            ) : (
              autonomieData && (
                <div>
                  <AutonomieIndicator
                    date={switcherDate}
                    data={autonomieData}
                    period={period}
                    unit={unit}
                  />
                </div>
              )
            )}{" "}
          </Switcher>
        </Panel>
      }
      rightSide={
        monitoringWithForecastIsError ||
        (manualMonitoringResponse[1] && manualMonitoringResponse[1].isError) ? (
          <EmsAlert
            status="error"
            title={"Erreur lors de la récupération des données"}
            description={"Error"}
          ></EmsAlert>
        ) : monitoringWithForecastIsLoading ||
          (manualMonitoringResponse[1] &&
            manualMonitoringResponse[1].isLoading) ? (
          <div>
            <Panel
              className="md:max-w-[90%]"
              title={
                <div>
                  <div className="indicators__list-indicators__graphs-indProd-title">
                    Ma production globale d'énergie
                  </div>
                </div>
              }
            >
              <Skeleton className="h-full ">
                <div className=" w-full h-full"></div>
              </Skeleton>
            </Panel>
            <Panel
              className="md:max-w-[90%]"
              title={
                <div className="indicators__list-indicators__graphs-indConso-title">
                  {" "}
                  Ma consommation globale d'énergie{" "}
                </div>
              }
            >
              <Skeleton className="h-full ">
                <div className=" w-full h-full"></div>
              </Skeleton>
            </Panel>
          </div>
        ) : (
          <>
            <Panel
              className="md:max-w-[90%]"
              title={
                <div>
                  <div className="indicators__list-indicators__graphs-indProd-title">
                    {" "}
                    Ma production globale d'énergie{" "}
                    <span className=" text-[#0488F9]  text-xl md:text-2xl font-extrabold mx-2">
                      {" "}
                      {pProdGlobal.toFixed(1)} kWh
                    </span>
                  </div>
                </div>
              }
              link="/my-solar-production"
            >
              <Switcher>
                <LabeledBarChart
                  data={graphProd}
                  displayCols
                  getDataForLabel={getDataForLabel}
                />
              </Switcher>
            </Panel>

            <Panel
              className="md:max-w-[90%]"
              title={
                <div className="indicators__list-indicators__graphs-indConso-title">
                  {" "}
                  Ma consommation globale d'énergie{" "}
                  <span className=" text-[#0488F9]  text-xl md:text-2xl font-extrabold mx-2">
                    {" "}
                    {pConsoGlobal.toFixed(1)} kWh
                  </span>
                </div>
              }
              link="/my-solar-consumption"
            >
              <Switcher>
                <LabeledBarChart
                  data={graphConso}
                  displayCols
                  getDataForLabel={getDataForLabel}
                />
              </Switcher>
            </Panel>
          </>
        )
      }
    />
  );
};
