import { ReactNode, memo, useEffect, useState } from "react";
import "../assets/scss/layouts/ResponsiveLayout.scss";
import { WeatherIndicator } from "../components/WeatherIndicator";
interface ResponsiveLayoutProps {
  header?: ReactNode;
  leftSide: ReactNode;
  rightSide: ReactNode;
}
export const ResponsiveLayout: React.FC<ResponsiveLayoutProps> = memo(({
  header,
  leftSide,
  rightSide,
}) => {
  const [middleHeight, setMiddleHeight] = useState(0);

  useEffect(() => {
    const resizeHandler = () => {
      const viewportHeight = window.innerHeight;
      const headerHeight = document?.getElementById('header')?.offsetHeight;
      const footerHeight = document?.getElementById('navbar')?.offsetHeight;
      if( headerHeight && footerHeight ){
        const calculatedMiddleHeight = viewportHeight - headerHeight - footerHeight;
        setMiddleHeight(calculatedMiddleHeight);
      }
    };

    // Appel initial
    resizeHandler();

    // Écouteur d'événement pour les changements de taille de fenêtre
    window.addEventListener('resize', resizeHandler);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);
    
  return (
    <>
    <div className="hidden md:flex flex-col  w-full h-full mx-auto  bg-gradient-to-b from-[#74B8FF] to-[#FFFFFF] to-100%">
      <WeatherIndicator/>
      <div className="flex flex-col md:flex-row gap-2 px-2">
        <div className="w-full h-full md:w-1/2 flex max-h-fit overflow-scroll py-2 pb-36 left"  style={{maxHeight:middleHeight}} >{leftSide}</div>
        <div className="flex  flex-col gap-4 md:w-1/2 h-full overflow-scroll pb-36 right"  style={{maxHeight:middleHeight}}>
          {rightSide}
        </div>
      </div>
    </div>
    <div className="flex  flex-col  md:hidden w-full h-full   mx-auto bg-gradient-to-b from-[#74B8FF] to-[#FFFFFF] to-100%">
      <WeatherIndicator/>
      <div className="flex flex-col md:flex-row gap-2 px-2 py-2">
        <div className="w-full md:w-1/2 flex max-h-fit py-2 left">{leftSide}</div>
        <div className="flex flex-col gap-4 md:w-1/2 h-full overflow-scroll py-2  pb-36 right">
          {rightSide}
        </div>
      </div>
    </div>
    </>
    
  );
});
