 import { createApi  } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./ems.service";
import { ResponseType } from "../utils/toolbox";
const PATH_COACHING = `eco-coaching`;
export interface ScoreResponseType extends ResponseType {
    topoID:number;
    score:number;
    commonMiniSurplusHour : number;
    commonMaxiSurplusHour:number;
    commonMiniWorstHour:number;
    commonMaxiWorstHour:number;
    commonMiniBestHour : number;
    commonMaxiBestHour:number;
    dpe:string;
    conso:number;
    ges:string;
    co2:number;
    dpeReal:string;
    gesReal:string;
    meanWorstHour:number;
    meanSurplusHour:number;
    meanBestHour:number
}
export const coachingApi = createApi({
  reducerPath: "coachingApi",
  baseQuery:  baseQueryWithReauth,
  endpoints: (builder) => ({ 
    getScoreByTopo :  builder.query<
    ScoreResponseType  ,
    { 
      topoId?: string;
    }
  >({
    query: ({topoId}) => ({
      url: `${PATH_COACHING}/score?topoId=${topoId}`,
      method: "GET",
    }),
  })
  }),
});
