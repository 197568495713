import { URIs } from "../utils/toolbox";
import { CrudUri } from "../components/CrudUri";
import { RadioForm } from "../components/RadioForm";
import { FormControl } from "@chakra-ui/react";
import React, { startTransition, useState } from "react";
export const TopologyAdminPage = () =>{
    const [selectedItem, setSelectedItem] = useState("fluxTopology");

    return <div className="max-w-screen">
    <FormControl className="control">
      <RadioForm
      value={selectedItem}
        options={[
          {
            value: URIs.FluxTopologyURI,
            viewValue: "Flux Topology",
          },

          {
            value: URIs.FluxNodeURI,
            viewValue: "Flux Node",
          },

          {
            value: URIs.FluxNodeParameterURI,
            viewValue: "Flux Node Parameter",
          },

          {
            value:URIs.MeasurementSourceURI,
            viewValue: "Measurement Source",
          },

          {
            value: URIs.MeasurementSourceParameterURI,
            viewValue: "Measurement SourceParameter",
          },
          {
            value: URIs.ParameterDescriptorURI,
            viewValue: "Parameter Descriptor",
          } 
        ]}
        onChange={(v) => {
          startTransition(() => {
          setSelectedItem(v);
        });
        }}
        defaultValue={selectedItem}
      ></RadioForm>
    </FormControl>
    {<CrudUri uri={selectedItem} />}
  </div>
}