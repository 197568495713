import React from "react";
import "../assets/scss/layouts/LoginLayout.scss";
import { Footer } from "../components/Footer";
interface LoginLayoutProps {
  children: React.ReactNode;
  className?: string;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children, className }) => {
  return (
    <>
      <div className="login-desktop ">
        <div className="leftSide min-h-screen flex flex-col">
          <div className="flex-grow flex justify-center ">{children}</div>
        </div>
        <div className="rightSide"></div>
      </div>
      <div className="login-mobile  min-h-screen flex flex-col">
        <div className="flex-grow flex justify-center ">{children}</div>
      </div>
    </>
  );
};

export default LoginLayout;
