import { COLORS, formatter } from "../utils/toolbox";
import Panel from "./Panel";
import { Switcher } from "./Switcher";
import { DoughnutGraph } from "./DoughnutGraph";
import { LabelWithValue } from "./LabelWithValue";
import { memo } from "react";

interface HomePageIndicatorProps {
  title: string;
  link: string;
  unit: string;
  globalValue: string;
  graphData: DoghnutData;
  energyDistribution: EnergyDistribution[];
}
interface DoghnutData {
  backgroundColor: string[];
  labels: string[];
  datas: Number[] | undefined;
}
interface EnergyDistribution {
  value: string;
  color: COLORS;
  label: string;
}
export const HomePageIndicator: React.FC<HomePageIndicatorProps> = memo(
  ({ title, link, unit, globalValue, graphData, energyDistribution }) => {
    return (
      <Panel className="max-w-fit" title={title} link={link}>
        <Switcher>
          <div className="flex flex-col ">
            <p className="text-center text-xl text-[#0488F9] my-4 font-semibold">
              <span>{globalValue}</span>
            </p>
            <DoughnutGraph
              formatter={formatter(unit)}
              backgroundColor={graphData.backgroundColor}
              labels={graphData.labels}
              datas={graphData.datas}
            />
            {energyDistribution.map((elt, index) => {
              return <LabelWithValue key={index} {...elt} />;
            })}
          </div>
        </Switcher>
      </Panel>
    );
  }
);
