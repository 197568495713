import React from "react";
import { ReactComponent as BatteryEmpty } from '../assets/img/battery/Battery-0.svg';
import { ReactComponent as Battery10 } from '../assets/img/battery/Battery-10.svg';
import { ReactComponent as Battery20 } from '../assets/img/battery/Battery-20.svg';
import { ReactComponent as Battery30 } from '../assets/img/battery/Battery-30.svg';
import { ReactComponent as Battery40 } from '../assets/img/battery/Battery-40.svg';
import { ReactComponent as Battery50 } from '../assets/img/battery/Battery-50.svg';
import { ReactComponent as Battery60 } from '../assets/img/battery/Battery-60.svg';
import { ReactComponent as Battery70 } from '../assets/img/battery/Battery-70.svg';
import { ReactComponent as Battery80 } from '../assets/img/battery/Battery-80.svg';
import { ReactComponent as Battery90 } from '../assets/img/battery/Battery-90.svg';
import { ReactComponent as Battery100 } from '../assets/img/battery/Battery-100.svg';

interface BatteryProps {
    batteryValue?: number;
    className?:string;
}

const Battery: React.FC<BatteryProps> = ({ batteryValue = 0,className}) => {
    let component=null;
    
    if (batteryValue < 10) {
        component = <BatteryEmpty className={className } />
    } else if (batteryValue >= 10 && batteryValue < 20) {
        component = <Battery10  className={className }  />
    } else if (batteryValue >= 20 && batteryValue < 30) {
        component = <Battery20  className={className }  />
    } else if (batteryValue >= 30 && batteryValue < 40) {
        component = <Battery30  className={className } />
    } else if (batteryValue >= 40 && batteryValue < 50) {
        component = <Battery40  className={className }  />
    } else if (batteryValue >= 50 && batteryValue < 60) {
        component = <Battery50  className={className }  />
    } else if (batteryValue >= 60 && batteryValue < 70) {
        component = <Battery60  className={className }  />
    } else if (batteryValue >= 70 && batteryValue < 80) {
        component = <Battery70  className={className }  />
    } else if (batteryValue >= 80 && batteryValue < 90) {
        component = <Battery80  className={className } />
    } else if (batteryValue >= 90 && batteryValue < 100) {
        component = <Battery90  className={className }  />
    } else if (batteryValue === 100) {
        component = <Battery100  className={className}  />
    }

    return component
}

export default Battery;