import React, { ReactElement } from "react";
import {
  useDisclosure,
  Icon
} from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import { ReactComponent as InstallationIcon } from "../assets/icons/InstallationIcon.svg";
import { ReactComponent as IndicateursIcon } from "../assets/icons/IndicateursIcon.svg";
import { ReactComponent as CoachingIcon } from "../assets/icons/CoachingIcon.svg";
import { ReactComponent as BookIcon } from "../assets/icons/BookIcon.svg";
import { ReactComponent as BatteryIcon } from "../assets/icons/battery_icon.svg";
import "../assets/scss/components/AppBar.scss";
import { MdLogout, MdSolarPower } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { EmsModal } from "./EmsModal";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { adminRoles, userRoles } from "../utils/toolbox";
import { SettingsIcon } from "@chakra-ui/icons";
import { logoutAction, selectAuth } from "../reducers/authReducer";
import { useDispatch } from "react-redux";
import { ResponsiveNavBar } from "./ResponsiveNavBar";

interface AppBarprops {
}
interface MenuItemType {
  name: string;
  text: string;
  url: string;
  show: boolean;
  callback?: () => void;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}
type MenuHorizontalItemType = Pick<
  MenuItemType,
  "name" | "text" | "url" | "show" | "callback"
> & {
  allowedRoles: string[];
  iconReactElement?: ReactElement;
};
const AppBar: React.FC<AppBarprops> = ({}) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); 
  const auth = selectAuth(store.getState());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    onClose();
    dispatch(logoutAction());
    localStorage.clear();
    navigate("/login");
  };
  const tabsDesktop: MenuItemType[] = [
    {
      name: "installation",
      text: "Installation",
      url: "/my-installation",
      show: !!getSelectedTopo(store.getState()).id,
      icon: InstallationIcon,
    },
    {
      name: "indicators",
      text: "Indicateurs",
      url: "/indicators",
      show: !!getSelectedTopo(store.getState()).id,
      icon: IndicateursIcon,
    },
    {
      name: "battery",
      text: "Batterie",
      url: "/my-battery",
      show: !!getSelectedTopo(store.getState()).id,
      icon: BatteryIcon,
    },
    {
      name: "coaching",
      text: "Coaching",
      url: "/my-coaching",
      show: !!getSelectedTopo(store.getState()).id,
      icon: CoachingIcon,
    },
  ];
  const drawerItemsMobile: MenuHorizontalItemType[] = [
    {
      name: "installation",
      text: "Installation",
      url: "/my-installation",
      show: !!getSelectedTopo(store.getState()).id,
      iconReactElement: (
        <Icon as={InstallationIcon} color={"white"} boxSize={6} />
      ),
      allowedRoles: [...userRoles()],
    },
    {
      name: "indicators",
      text: "Indicateurs",
      url: "/indicators",
      show: !!getSelectedTopo(store.getState()).id,
      iconReactElement: (
        <Icon as={IndicateursIcon} color={"white"} boxSize={6} />
      ),
      allowedRoles: [...userRoles()],
    },
    {
      name: "battery",
      text: "Batterie",
      url: "/my-battery",
      show: !!getSelectedTopo(store.getState()).id,
      iconReactElement: <Icon as={BatteryIcon} color={"white"} boxSize={6} />,
      allowedRoles: [...userRoles()],
    },
    {
      name: "coaching",
      text: "Coaching",
      url: "/my-coaching",
      show: !!getSelectedTopo(store.getState()).id,
      iconReactElement: <Icon as={CoachingIcon} color={"white"} boxSize={6} />,
      allowedRoles: [...userRoles()],
    },
    {
      name: "installationDetails",
      url: "/installation-details",
      show: !!getSelectedTopo(store.getState()).id,
      text: "Mon installation",
      iconReactElement: <Icon as={MdSolarPower} color={"white"} boxSize={6} />,
      allowedRoles: [...userRoles()],
    },
    {
      name: "lexicon",
      url: "/lexicon",
      show: true,
      text: "Lexique",
      iconReactElement: <Icon as={BookIcon} color={"white"} boxSize={6} />,
      allowedRoles: [...userRoles()],
    },
    {
      name: "administration",
      url: "/administration",
      show: true,
      text: "Administration",
      iconReactElement: <Icon as={SettingsIcon} color={"white"} boxSize={6} />,
      allowedRoles: [...adminRoles()],
    },
    {
      name: "monCompte",
      url: "/mon-compte",
      show: true,
      text: "Mon compte",
      iconReactElement: (
        <Avatar
          name={auth.user?.lastName + " " + auth.user?.firstName}
          src=""
          size={{ base: "sm" }}
          boxSize={6}
        />
      ),
      allowedRoles: [...userRoles()],
    },
    {
      name: "logout",
      url: "",
      show: true,
      text: " Se déconnecter",
      iconReactElement: <Icon as={MdLogout} color={"white"} boxSize={6} />,
      allowedRoles: [...userRoles()],
      callback: onOpen,
    },
  ]; 
  return (
    <>
      <EmsModal
        headerMessage="Déconnexion"
        message="Vous souhaitez quitter votre session ?"
        isOpen={isOpen}
        onClose={onClose}
        accept={logout}
        reject={onClose}
      />
      <ResponsiveNavBar
        menuItems={drawerItemsMobile}
        tabItems={tabsDesktop}
        className="hidden md:flex "
      />
      <ResponsiveNavBar
        menuItems={drawerItemsMobile}
        tabItems={[]}
        className="flex md:hidden "
      />
    </>
  );
};

export default AppBar;
