import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import { ReactNode } from "react";

interface EmsModalProps {
    isOpen : boolean;
    onClose :()=>void
    accept : ()=>void
    reject : ()=>void
    headerMessage : string | ReactNode;
    message :string;
}
export const  EmsModal: React.FC<EmsModalProps>  = ({isOpen,onClose,accept,reject,headerMessage,message}) => {
    return (
      <>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{headerMessage} </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Text >{message} </Text>
            </ModalBody>

            <ModalFooter>
              
              <Button  bg={"red"} colorScheme='red' _hover={{ bg: "red" }} mr={3} onClick={accept}>
                Oui
              </Button>
              <Button  _hover={{ bg: "#003265" }} bg={"#003265"} color={"white"} mr={3} onClick={reject}>
                Non
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }