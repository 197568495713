import React from 'react';

const MyInvoicePage: React.FC = () => {
    return (
        <div>
            {}
            <h2>Ma facture énergétique</h2>
        </div>
    );
}

export default MyInvoicePage;