
import { ReactNode } from "react";
import "../assets/scss/components/Card.scss";
interface CardProps {
    children?: ReactNode;
    className? : string

}
export const Card : React.FC<CardProps> = ({children,className})=>{


    return <div className= {`bg-white rounded-[20px] ${className}`}>{children}</div>
}