import { ArcElement, Chart, Tooltip } from "chart.js";
import DataLabel from "chartjs-plugin-datalabels";
import React from "react";
import "../assets/scss/pages/HomePage.scss";
import { HomePageGraphs } from "../components/HomePageGraphs";
import { HomeScreen } from "../components/HomeScreen";
import { WeatherIndicator } from "../components/WeatherIndicator";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
Chart.register(ArcElement, Tooltip, DataLabel);

export const HomePage: React.FC = () => {
  return (
    <ResponsiveLayout
      header={<WeatherIndicator />}
      leftSide={<HomeScreen />}
      rightSide={<HomePageGraphs />}
    />
  );
};
