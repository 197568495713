import { Showforpermission } from "../components/Showforpermission";
import { Navigate, Outlet } from "react-router-dom"; 
import { selectAuth } from "../reducers/authReducer";
import { store } from "../store/config";
interface PrivateRouteProps {
    allowedRoles : string []
}
export const PrivateRoute : React.FC<PrivateRouteProps> = ({allowedRoles})=>{
    const auth = selectAuth(store.getState())
    if( !auth.isAuthenticated)  return <Navigate to="/login" />;
    return <Showforpermission allowedRoles={allowedRoles }><Outlet /></Showforpermission>;
}