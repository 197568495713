import { customBaseQuery, getTokenFormLocalStorage } from "../utils/toolbox";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./ems.service";

export type AuthRequestType = {
  id:string
};

export type User = {
  id: string;
  token?: string;
  refresh_token?: string;
  createdTimestamp: string;
  username: string;
  enabled: boolean;
  totp: boolean;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  email: string;
  notBefore: number;
  realmRoles: string[];
};
const PATH_USERS = `users`;

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllUsers: builder.query<User[], AuthRequestType>({
      query: () => ({
        url: `${PATH_USERS}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),

      transformResponse: (rawResult: User[], meta) => {
        return rawResult;
      },
    }),
    getUsersByRoles: builder.query<User[], { roles: string[] }>({
      query: ({ roles }) => ({
        url: `${PATH_USERS}/byRoles`,
        method: "POST",
        body: {
          realmRoles: roles,
        },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),

      transformResponse: (rawResult: User[], meta) => {
        return rawResult;
      },
    }),
    getUserById: builder.query<User, AuthRequestType>({
      query: (params) => ({
        url: `${PATH_USERS}/${params.id}`,
        method: "GET",
      }),
      transformResponse: (rawResult: User, meta) => {
        return rawResult;
      },
    }),
    updateUser: builder.mutation<
      Boolean,
      Pick<User, "id" | "firstName" | "lastName">
    >({
      query: (user) => ({
        url: `${PATH_USERS}/update`,
        method: "POST",
        body: user,
      }),
      transformResponse: (rawResult: Boolean, meta) => {
        return rawResult;
      },
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useLazyGetAllUsersQuery,
  useGetUsersByRolesQuery,
  useLazyGetUsersByRolesQuery,
  useLazyGetUserByIdQuery
} = usersApi;
