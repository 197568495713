import Panel from "../components/Panel";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "../assets/scss/pages/MySolarProduction.scss";
import LabeledBarChart from "../components/LabeledBarChart";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { COLORS, TimeLabels, getFormatedDatetime } from "../utils/toolbox";
import {
  useLazyGetManualMonitoringWithFilterDataQuery,
  useLazyGetMonitoringActualAndForecastDataQuery,
} from "../services/ems.service";
import EmsAlert from "../components/EmsAlert";
import { Box, Skeleton } from "@chakra-ui/react";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { useNavigate } from "react-router-dom";
import { Switcher } from "../components/Switcher";
import moment from "moment";
//svgs

import {ReactComponent as Sun} from "../assets/icons/ifpen-sun.svg";
import {ReactComponent as BatteryUp}  from "../assets/icons/ifpen-battery-up.svg";
import {ReactComponent as Pylon}  from "../assets/icons/ifpen-pylon.svg";
import {ReactComponent as BatteryDown}  from "../assets/icons/ifpen-battery-down.svg" 
ChartJS.register(CategoryScale, LinearScale, BarElement, zoomPlugin);

const MySolarProduction: React.FC = () => {
  const { unit, period, switcherDate, setSwitcherDate, setPeriod } =
    useSwitcherContext();
  const topologyId = getSelectedTopo(store.getState()).id;
  const manualMonitoringResponse =
    useLazyGetManualMonitoringWithFilterDataQuery();
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();
  const [
    getMonitoringWithForecast,
    {
      isError: monitoringWithForecastIsError,
      isLoading: monitoringWithForecastIsLoading,
    },
  ] = useLazyGetMonitoringActualAndForecastDataQuery();

  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());

    const fetchData = async () => {
      const data = await getMonitoringWithForecast({
        date: moment(switcherDate).toDate(),
        topologyId: topologyId,
      });
      return data.data;
    };
    fetchData()
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.log(err));
  }, [switcherDate]);

  const fetchMonitorungManualData = async (
    startDateTime: Date,
    endDateTime: Date,
    aggregation: string
  ) => {
    const data = await manualMonitoringResponse[0]({
      startDateTime,
      endDateTime,
      aggregation,
      topologyId,
    });

    return data.data.data;
  };
  const getDataForLabel = (timelabel: TimeLabels) => {
    const dateLabel = new Date(timelabel.instant);
    switch (period) {
      case "day":
        if (Number(timelabel.label.split("h")[0]) < 4) return;
        let startDateTime = new Date(timelabel.instant);
        startDateTime.setHours(startDateTime.getHours() - 1);
        let endDateTime = new Date(timelabel.instant);
        let aggregation = "10m";
        fetchMonitorungManualData(startDateTime, endDateTime, aggregation)
          .then((res) => {
            setData({
              day: res,
            });
          })
          .catch((err) => console.log(err));
        break;
      case "week":
        dateLabel.setHours(dateLabel.getHours() + 2);
        setSwitcherDate(dateLabel);
        setPeriod("day");
        break;
      case "month":
        dateLabel.setHours(dateLabel.getHours() + 2);
        setSwitcherDate(dateLabel);
        setPeriod("day");
        break;
      case "year":
        dateLabel.setHours(dateLabel.getHours() + 2);
        setSwitcherDate(dateLabel);
        setPeriod("month");
        break;
      default:
        break;
    }

    return;
  };

  const labelsProd: any[] = [];
  const pProdConsByBat: any[] = [];
  const pProdSentToGrid: any[] = [];
  const pProdConsByConsumers: any[] = [];

  const pProdConsByBatForecast: any[] = [];
  const pProdSentToGridForecast: any[] = [];
  const pProdConsByConsumersForecast: any[] = [];

  //Actual
  if (data && data.outputDTOActual && data.outputDTOActual[period]) {
    const actual = data.outputDTOActual;
    const filterDayTime = (item: any) =>
      actual[period].aggregation == "1h" ? item.dayTime : true;
    actual[period]["pProdConsByBat" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        getFormatedDatetime(item, period, labelsProd);
        pProdConsByBat.push(item.energy.toFixed(1));
      });

    actual[period]["pProdConsByConsumers" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        pProdConsByConsumers.push(item.energy.toFixed(1));
      });

    actual[period]["pProdSentToGrid" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        pProdSentToGrid.push(item.energy.toFixed(1));
      });
  }

  //forecast
  if (data && data.outputDTOForeacasted && data.outputDTOForeacasted[period]) {
    const forecasted = data.outputDTOForeacasted;
    const filterDayTime = (item: any) =>
      forecasted[period].aggregation == "1h" ? item.dayTime : true;
    const addItem = (array: any[], item: any) => {
      array.push(item.energy.toFixed(1));
    };
    forecasted[period]["pProdConsByBat" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        getFormatedDatetime(item, period, labelsProd);
        addItem(pProdConsByBatForecast, item);
      });

    forecasted[period]["pProdConsByConsumers" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        addItem(pProdConsByConsumersForecast, item);
      });
    forecasted[period]["pProdSentToGrid" + unit]
      .filter(filterDayTime)
      .forEach((item: any) => {
        addItem(pProdSentToGridForecast, item);
      });
  }
  const graphProd = {
    labels: labelsProd,
    datasets: [
      {
        key:1,
        label: "Autoconsommation directe",
        data: pProdConsByConsumers,
        image: Sun,
        darkImage:Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0)",
        borderWidth: 0,
      },{
        key:1,
        label: "Prédiction autoconsommation directe",
        data: pProdConsByConsumersForecast,
        image: Sun,
        darkImage:Sun,
        bgColor:  COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0,0.5)",
        borderWidth: 0,
        isForecast : true
      },
      { key:2,
        label: "Charge batterie",
        image: BatteryUp,
        darkImage:BatteryUp,
        data: pProdConsByBat,
        bgColor: COLORS.BATTERYUP,
        backgroundColor:"rgb(66, 216, 40)",
        borderWidth: 0,
      },
      {
        key:2,
        label: "Prédiction charge batterie",
        image: BatteryUp,
        darkImage:BatteryUp,
        data: pProdConsByBatForecast,
        bgColor:  COLORS.BATTERYUP,
        backgroundColor: "rgb(66, 216, 40,0.5)",
        borderWidth: 0,
        isForecast : true
      },
      {
        key:3,
        label: "Surplus réinjecté dans le réseau",
        image: Pylon,
        darkImage:BatteryDown,
        data: pProdSentToGrid,
        bgColor: COLORS.SURPLUS,
        backgroundColor: "rgb(224, 47, 239)",
        borderWidth: 0,
      },
      {
        key:3,
        label: "Prédiction surplus réinjecté dans le réseau",
        image: Pylon,
        darkImage:Pylon,
        data: pProdSentToGridForecast,
        bgColor: COLORS.SURPLUS,
        backgroundColor: "rgb(224, 47, 239,0.5)",
        borderWidth: 0,
        isForecast : true
      },
    ],
  };

  return (
    <>
      <div className="w-full">
        <div className="container  w-full h-full flex flex-col items-center mx-auto pt-4 gap-2 md:gap-4 flex-col gap-2 hidden md:flex">
          <div className="w-1/2 flex">
          <Panel>
            <Switcher />
          </Panel>
          </div>
          {monitoringWithForecastIsError ||
          (manualMonitoringResponse[1] &&
            manualMonitoringResponse[1].isError) ? (
            <EmsAlert
              status="error"
              title={"Erreur lors de la récupération des données"}
              description={"Error"}
            ></EmsAlert>
          ) : (
            <div className="w-full">
              {monitoringWithForecastIsLoading ||
              (manualMonitoringResponse[1] &&
                manualMonitoringResponse[1].isLoading) ? (
                <Skeleton className="w-full mx-2 my-4 md:mb-5 md:mt-4">
                  <div className=" w-full h-full md:w-[400px]"></div>
                </Skeleton>
              ) : (
                <Panel className="w-full">
                  <LabeledBarChart
                    data={graphProd}
                    getDataForLabel={getDataForLabel}
                  />
                </Panel>
              )}
            </div>
          )}
        </div>
        <Box className="flex md:hidden">
          {monitoringWithForecastIsError ||
          (manualMonitoringResponse[1] &&
            manualMonitoringResponse[1].isError) ? (
            <EmsAlert
              status="error"
              title={"Erreur lors de la récupération des données"}
              description={"Error"}
            ></EmsAlert>
          ) : (
            <div className="p-2 w-full h-full md:px-4 py-auto flex">
              <Skeleton
                isLoaded={
                  !(
                    manualMonitoringResponse[1].isLoading ||
                    monitoringWithForecastIsLoading
                  )
                }
                className="flex my-auto w-full h-full"
              >
                <Panel className="w-[100%] h-full min-w-full  md:h-full">
                  <Switcher units={["kWh", "Euro"]}>
                    <LabeledBarChart
                      data={graphProd}
                      getDataForLabel={getDataForLabel}
                      displayCols
                    />
                  </Switcher>
                </Panel>
              </Skeleton>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default MySolarProduction;
