import { MSParameterDto } from "../components/SourceDonnees";
import { FluxNodeParameterDto } from "../components/InstallationPhysique";
import { createApi  } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./ems.service";

export interface ResponseType {
  [key: string]:
    | string
    | number
    | Object
    | undefined
    | { [key: string]: ResponseType };
}

export interface BodyType {}
export interface User {
  id:string;
  groupe:string
}
export interface AdvancedFluxNodeDto {
  typeNoeud : string;
  fluxNodeParameters: FluxNodeParameterDto[];
  measurementSourceParameters: MSParameterDto[];
}
export interface AdvancedFluxTopologyBody extends BodyType {
  latitude: number;
  longitude: number;
  installationDate?: Date;
  adresse  : string;
  owner: User;
  advancedFluxNodeDtos : { 
    GROUP: AdvancedFluxNodeDto ;
    CONSUMER: AdvancedFluxNodeDto 
    PRODUCER?: AdvancedFluxNodeDto 
    BATTERY?: AdvancedFluxNodeDto 

  } ;
  
}export enum NODES {
  GROUP = "GROUP",
  CONSUMMER = "CONSUMMER",
  PRODUCER = "PRODUCER",
  BATTERY = "BATTERY" 
}
const PATH_TOPOLOGY = `topologies`;

export const topologyApi = createApi({
  reducerPath: "topologyApi",
  baseQuery:  baseQueryWithReauth,
  endpoints: (builder) => ({
    getAll: builder.query<
      ResponseType[],
      {
        uri: string;
        body?: BodyType;
        id?: string;
        membership ?: string
      }
    >({
      query: (request) => ({ url: `${PATH_TOPOLOGY}/${request.uri}/all${request.membership ? "/"+request.membership : ""}`, method: "GET" }),
      transformResponse: (rawResult: ResponseType[], meta) => {
        return rawResult;
      },
    }),
    getOne: builder.query<
      ResponseType  ,
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${PATH_TOPOLOGY}/${request.uri}/${request.id}`,
        method: "GET",
      }),
    }),
    getByCritere: builder.mutation<
      ResponseType[],
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${PATH_TOPOLOGY}/${request.uri}/criteria`,
        method: "POST",
        body: request.body,
      }),
    }),
    findByOwner: builder.mutation<
    ResponseType[],
    {
      uri: string;
      body?: BodyType;
      id?: string;
    }
  >({
    query: (request) => ({
      url: `${PATH_TOPOLOGY}/${request.uri}/findByOwner`,
      method: "POST",
      body: request.body,
    }),
  }),
    update: builder.mutation<
      ResponseType,
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${PATH_TOPOLOGY}/${request.uri}`,
        method: "PUT",
        body: request.body,
      }),
    }),
    create: builder.mutation<
      ResponseType,
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${PATH_TOPOLOGY}/${request.uri}`,
        method: "POST",
        body: request.body,
      }),
    }),
    avdancedCreate: builder.mutation<
      ResponseType,
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${PATH_TOPOLOGY}/${request.uri}/create`,
        method: "POST",
        body: request.body,
      }),
    }),
    delete: builder.mutation({
      query: (request) => ({
        url: `${PATH_TOPOLOGY}/${request.uri}/${request.id}`,
        method: "DELETE",
      }),
    }),
  }),
});
