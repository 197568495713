import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Button,
  useDisclosure,
  Td,
} from "@chakra-ui/react";
import { ResponseType } from "../services/topology.service";
import { Field } from "../services/parametrage.service";
import { DeleteIcon, EditIcon, SmallAddIcon } from "@chakra-ui/icons";
import { ModalUpdate } from "./ModalUpdate";
import { EmsModal } from "./EmsModal";
import { ModalCreate } from "./ModalCreate";
import {
  PATH_PREFIX_FLUXTOPOLOGY,
  URIs,
  getLoadingDataErrorByStatus,
  getUriByClassName,
} from "../utils/toolbox";
import { DetailsModal } from "./DetailsModal";
import useGetMembership from "../hooks/useGetMembership";
import { TablePaginator } from "./TablePaginator";
import EmsAlert from "./EmsAlert";
import { crudApi } from "../services/crud.service";

interface CrudProps {
  obj: string;
  uri: string;
  title: string;
}
interface DataType extends ResponseType {}

export const Crud: React.FC<CrudProps> = ({ uri, title, obj }) => {
  const { useDeleteMutation, useLazyGetAllQuery, useGetByCritereMutation } =
    crudApi;

  const [getAll, getAllResponse] = useLazyGetAllQuery();
  const [getByCritere, getByCritereResponse] = useGetByCritereMutation();
  const [deleteElt, deleteResponse] = useDeleteMutation();
  const memberships = useGetMembership();
  const [membership, setMembership] = useState("");

  const [fields, setFields] = useState<ResponseType[]>([]);

  const [showAddNewElement, setShowAddNewElement] = useState<boolean>(false);

  const [showUpdateNewElement, setShowUpdateNewElement] =
    useState<boolean>(false);

  const [elementToModify, setElementToModify] = useState<string>("");
  const [elementToDelete, setElementToDelete] = useState<string>("");
  const handleShowAddNewElement = () => {
    setShowAddNewElement(true);
  };

  const [showDetailsElement, setShowDetailsElement] = useState<{
    show: boolean;
    id: string;
    className: string;
  }>({ show: false, id: "", className: "" });
  const showDetails = (id: string, className: string) => {
    setShowDetailsElement({ show: true, className, id }); // findOne(getUriByClassName(className))
  };
  const [isErrorOnDelete, setIsErrorOnDelete] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  //roles
  useEffect(() => {
    if (memberships.length == 1) {
      setMembership(memberships[0]);
    } else if (memberships.length == 2) {
      setMembership("");
    }
  }, [memberships]);

  useEffect(() => {
    setCurentPage(1);
    setShowUpdateNewElement(false);
    getByCritere({
      uri: PATH_PREFIX_FLUXTOPOLOGY + "/" + URIs.ParametrageURI,
      body: { className: obj },
    })
      .unwrap()
      .then((res) => {
        let fields: Field[] = res as Field[];
        if (fields) {
          setFields(fields);
        }
      });
    getAll({ uri })
      .unwrap()
      .then((_res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, [getAll, getByCritere, uri, obj]);
  const deleteElement = (id: string) => {
    deleteElt({
      uri,
      id,
    })
      .unwrap()
      .then(() => {
        setIsErrorOnDelete(false);
        onClose();
        //use refresh method
        let creteres = { uri };
        getAll(creteres)
          .unwrap()
          .then((_res) => {})
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        setIsErrorOnDelete(true);
        console.log(err);
      });
  };

  const getViewName = (key: string) => {
    let preferedName = key;
    fields.forEach((field) => {
      let newField: Field = field as Field;
      if (newField.name == key) {
        preferedName = newField.viewName;
        return;
      }
    });
    return preferedName;
  };
  const updateRow = (id: string) => {
    setElementToModify(id);
    setShowUpdateNewElement(true);
  };

  //pagination
  const [currentPage, setCurentPage] = useState<number>(1);
  const PageSize = 10;
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return getAllResponse.data
      ? getAllResponse.data.slice(firstPageIndex, lastPageIndex)
      : undefined;
  }, [currentPage, getAllResponse.data]);

  return (
    <>
      {" "}
      <EmsModal
        headerMessage={
          <div className="mt-3">
            {isErrorOnDelete && (
              <EmsAlert
                description={""}
                status="error"
                title="Erreur lors de la suppression ! "
              />
            )}
            <div>Suppression</div>
          </div>
        }
        message="Êtes-vous sûr de vouloir supprimer cet élément ?"
        isOpen={isOpen}
        onClose={onClose}
        accept={() => {
          deleteElement(elementToDelete);
        }}
        reject={onClose}
      />
      <div className=" max-w-screen mb-4">
        <>
          <div className="">
            <div className="flex flex-row items-center gap-4">
              <h1 className="font-extrabold text-xl m-2">{title}</h1>
              <Button colorScheme="blue" onClick={handleShowAddNewElement}>
                <SmallAddIcon boxSize={6} />
              </Button>
            </div>
            <div className=" max-h-[500px] w-full max-w-screen">
              {getByCritereResponse.isError ? (
                <div className="px-auto w-full">
                  <EmsAlert
                    status={"error"}
                    title={
                      "status" in getByCritereResponse.error
                        ? getLoadingDataErrorByStatus(
                            getByCritereResponse.error.status
                          ).titre
                        : getLoadingDataErrorByStatus(undefined).titre
                    }
                    description={
                      "status" in getByCritereResponse.error
                        ? getLoadingDataErrorByStatus(
                            getByCritereResponse.error.status
                          ).message
                        : getLoadingDataErrorByStatus(undefined).message
                    }
                  />
                </div>
              ) : getByCritereResponse.isLoading ? (
                <div className=" px-auto w-full">Loading</div>
              ) : getAllResponse.isError ? (
                <div className="mx-auto w-full">
                  <EmsAlert
                    status={"error"}
                    title={
                      "status" in getAllResponse.error
                        ? getLoadingDataErrorByStatus(
                            getAllResponse.error.status
                          ).titre
                        : getLoadingDataErrorByStatus(undefined).titre
                    }
                    description={
                      "status" in getAllResponse.error
                        ? getLoadingDataErrorByStatus(
                            getAllResponse.error.status
                          ).message
                        : getLoadingDataErrorByStatus(undefined).message
                    }
                  />
                </div>
              ) : getAllResponse.isLoading ? (
                <div className="px-auto w-full">Loading</div>
              ) : (
                <>
                  <TableContainer
                    maxHeight={"500px"}
                    className="max-w-screen border border-b-2"
                    overflowY={"scroll"}
                  >
                    {fields && fields.length > 0 && (
                      <Table variant="simple" className="max-w-screen">
                        <Thead
                          position={"sticky"}
                          zIndex={100}
                          top={0}
                          opacity={1}
                          backgroundColor={"#003265"}
                          textColor={"white"}
                        >
                          <Tr textColor={"white"}>
                            {fields && fields.map((field, index) => (
                              <Th textColor={"white"} key={index}>
                                {getViewName((field as Field).name)}{" "}
                              </Th>
                            ))}
                            <Th textColor={"white"} textAlign={"center"}>
                              Actions{" "}
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody overflow={"scroll"}>
                          {currentTableData &&
                            currentTableData.map((row, index) => {
                              // let keys = columns;
                              //let values = Object.values(row);
                              // let id = values[keys.indexOf("id")];
                              return (
                                <Tr key={index} className="font-bold">
                                  {fields.map((field, index) => {
                                    const element: Field = field as Field;
                                    const key = element.name;
                                    if (element.typeField == "CLASS") {
                                      let idKey = key + "Id";
                                      return (
                                        <Td key={index} className="text-center">
                                          {" "}
                                          {!(row[idKey] as string) ||
                                          !element.value ? (
                                            <>--------</>
                                          ) : (
                                            <Button
                                              onClick={() =>
                                                showDetails(
                                                  row[idKey] as string,
                                                  element.value
                                                )
                                              }
                                            >
                                              Détails
                                            </Button>
                                          )}
                                        </Td>
                                      );
                                    } else {
                                      if (typeof row[key] == "boolean") {
                                        return (
                                          <Td
                                            key={index}
                                            className="text-center"
                                          >
                                            {" "}
                                            {row[key] ? "Oui" : "NON"}
                                          </Td>
                                        );
                                      } else if (
                                        key.toLocaleLowerCase() == "img"
                                      ) {
                                        return (
                                          <Td
                                            key={index}
                                            className="text-center"
                                          >
                                            {" "}
                                            <img
                                              width={"50px"}
                                              height={"50px"}
                                              src={row[key] as string}
                                              alt=""
                                            />
                                          </Td>
                                        );
                                      }
                                      return (
                                        <Td key={index} className="text-center">
                                          {" "}
                                          {row[key] as string}
                                        </Td>
                                      );
                                    }
                                  })}
                                  <Td
                                    zIndex={1}
                                    className="  flex flex-row gap-4 justify-center"
                                  >
                                    {" "}
                                    <Button
                                      colorScheme="green"
                                      onClick={() => updateRow(row["id"] + "")}
                                    >
                                      <EditIcon boxSize={4} />
                                    </Button>{" "}
                                    <Button
                                      colorScheme="red"
                                      onClick={() => {
                                        setElementToDelete(row["id"] + "");
                                        onOpen();
                                      }}
                                    >
                                      <DeleteIcon boxSize={4} />
                                    </Button>{" "}
                                  </Td>
                                </Tr>
                              );
                            })}
                        </Tbody>
                      </Table>
                    )}
                  </TableContainer>
                  {
                    <TablePaginator
                      currentPage={currentPage}
                      className=""
                      pageSize={PageSize}
                      onPageChange={(nextPage) => setCurentPage(nextPage)}
                      siblingCount={1}
                      totalCount={
                        getAllResponse.data ? getAllResponse.data.length : 0
                      }
                    ></TablePaginator>
                  }
                </>
              )}
            </div>
          </div>
          {showDetailsElement.show && (
            <DetailsModal
              obj={showDetailsElement.className}
              title={"Détails"}
              id={showDetailsElement.id}
              uri={getUriByClassName(showDetailsElement.className)}
              setShowDetailsElement={setShowDetailsElement}
              showDetailsElement={showDetailsElement}
            />
          )}
          {showUpdateNewElement && (
            <ModalUpdate
              obj={obj}
              title={title}
              id={elementToModify}
              uri={uri}
              onUpdateOk={() => {
                let creteres = { uri: uri, membership };
                getAll(creteres)
                  .unwrap()
                  .then((_res) => {})
                  .catch((err) => {
                    console.log(err);
                  });
              }}
              setShowUpdateNewElement={setShowUpdateNewElement}
              showUpdateNewElement={showUpdateNewElement}
            />
          )}

          <ModalCreate
            obj={obj}
            onCreateOk={() => {
              let creteres = { uri: uri };
              getAll(creteres)
                .unwrap()
                .then((_res) => {})
                .catch((err) => {
                  console.log(err);
                });
            }}
            setShowCreateNewElement={setShowAddNewElement}
            showCreateNewElement={showAddNewElement}
            title={title}
            uri={uri}
          />
        </>
      </div>
    </>
  );
};
