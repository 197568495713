import { getSelectedTopo } from "../reducers/topologyReducer";
import { emsApi } from "../services/ems.service";
import { WeatherItem } from "./WeatherItem";
import { store } from "../store/config";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TimeLabels, getFormatedDatetime } from "../utils/toolbox";
import "../assets/scss/components/WeatherDetails.scss";
import { Skeleton } from "@chakra-ui/skeleton";
import { Button } from "@chakra-ui/button";
import { ChevronDownIcon, ChevronUpIcon, Icon } from "@chakra-ui/icons";
import moment from "moment";
import EmsAlert from "./EmsAlert";
export interface WeatherItemType {
  temperature: number;
  unit: string;
  iconId: string;
  weatherIconUrl: string;
  description: string;
  detailsDescription: string;
}
interface DataSet {
  id: string;
  label: string;
  data: number[];
  backgroundColor: (c: any) => void;
  borderWidth?: number;
  barThickness: number;
  categoryPourcentage: number;
}
export interface DataHour {
  hour: string;
  labels: string[];
  weather: WeatherItemType;
  datasets: DataSet[];
}

export const WeatherDetails: React.FC = () => {
  const navigate = useNavigate();
  const [actualDate, setActualDate] = useState(
    moment().add(1, "hour").toDate()
  );
  const [maxProd, setMaxProd] = useState(0);
  const [monitoringData, setMonitoringData] = useState<any>();
  const [dataByHours, SetDataByHours] = useState<DataHour[]>([]);
  const topologyId = getSelectedTopo(store.getState()).id;
  const { useLazyGetMonitoringWithForecastWithWeatherDatabyHoursQuery } =
    emsApi;
  const [
    getMonitoringWithForecastWithWeatherDatabyHours,
    { error: getMonitoringDataError, isError: getMonitoringDataIsError },
  ] = useLazyGetMonitoringWithForecastWithWeatherDatabyHoursQuery();
  //refresh data every minute
  useEffect(() => {
    let timer: any = null;
    const timout = setTimeout(() => {
      let date = moment().add(1, "hour");
      let roundedMinutes = Math.round(date.minutes() / 10) * 10;
      let startDateForecast = date.minutes(roundedMinutes).toDate();

      setActualDate(startDateForecast);
      timer = setInterval(() => {
        let date = moment().add(1, "hour");
        let roundedMinutes = Math.round(date.minutes() / 10) * 10;
        let startDateForecast = date.minutes(roundedMinutes).toDate();
        setActualDate(startDateForecast);
      }, 60000);
    }, (60 - new Date().getSeconds()) * 1000);

    return () => {
      window.clearInterval(timout);
      window.clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    const startDate = new Date();
    startDate.setUTCHours(0, 0, 0, 0);
    getMonitoringWithForecastWithWeatherDatabyHours({
      startDateTime: moment(actualDate).startOf("day").toDate(),
      endDateTime: moment(actualDate).add(1, "day").endOf("day").toDate(),
      topologyId,
      aggregation: "1h",
    })
      .unwrap()
      .then(
        (res) => {
          setMonitoringData(res.data || []);
          setMaxProd(res.totalMax);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [topologyId, actualDate]);

  useEffect(() => {
    const buildDataForHour = (
      monitoringData: any,
      hour: string,
      weather: WeatherItemType
    ) => {
      const labels: TimeLabels[] = [];
      const pProdConsByConsumers: any[] = [];
      const pProdConsByBat: any[] = [];
      const pProdSentToGrid: any[] = [];
      const unit = "kWh";
      if (monitoringData) {
        monitoringData["pProdConsByConsumers" + unit].forEach(
          (item: any, index: number) => {
            pProdConsByConsumers.push(item.measure.toFixed(1));
          }
        );
        monitoringData["pProdConsByBat" + unit].forEach(
          (item: any, index: number) => {
            getFormatedDatetime(item, "hour", labels);
            pProdConsByBat.push(item.measure.toFixed(1));
          }
        );
        monitoringData["pProdSentToGrid" + unit].forEach(
          (item: any, index: number) => {
            pProdSentToGrid.push(item.measure.toFixed(1));
          }
        );
      }

      const graphProd: DataHour = {
        hour: hour,
        labels: labels.map((label) => label.label),
        weather: weather,
        datasets: [
          {
            id: "production",
            label: "Autoconsommation directe",
            data: pProdConsByConsumers,
            backgroundColor: (c) => {
              let index = c.index;
              if (labels && labels.length > 0) {
                if (labels[index] && labels[index].instant) {
                  if (!labels[index].isForecasted) {
                    return "rgb(242, 125, 0)";
                  } else {
                    return "rgb(242, 125, 0,0.5)";
                  }
                }
              }
            },
            borderWidth: 0,
            barThickness: 50,
            categoryPourcentage: 1,
          },
          {
            id: "battery",
            label: "Charge batterie",
            data: pProdConsByBat,
            backgroundColor: (c) => {
              let index = c.index;
              if (labels && labels.length > 0) {
                if (labels[index] && labels[index].instant) {
                  if (!labels[index].isForecasted) {
                    return "rgb(66, 216, 40)";
                  } else {
                    return "rgb(66, 216, 40,0.5)";
                  }
                }
              }
            },
            borderWidth: 0,
            barThickness: 50,
            categoryPourcentage: 1,
          },
          {
            id: "surplus",
            label: "Surplus réinjecté dans le réseau",
            data: pProdSentToGrid,
            backgroundColor: (c) => {
              let index = c.index;
              if (labels && index <= labels.length - 1) {
                if (labels[index] && labels[index].instant) {
                  if (!labels[index].isForecasted) {
                    return "rgb(224, 47, 239)";
                  } else {
                    return "rgb(224, 47, 239,0.5)";
                  }
                }
              }
            },
            borderWidth: 0,
            barThickness: 50,
            categoryPourcentage: 1,
          },
        ],
      };
      return graphProd;
    };

    SetDataByHours((prev) => []);
    if (!!monitoringData) {
      Object.keys(monitoringData)
        .sort((s1, s2) => new Date(s1).getTime() - new Date(s2).getTime())
        .forEach((hour) => {
          let data4Hour: DataHour = buildDataForHour(
            monitoringData[hour].data,
            hour,
            monitoringData[hour].weather
          ); 
          SetDataByHours((prev) => [...prev, data4Hour]);
        });
    }
  }, [monitoringData]);
  // scroll
  const listRef = useRef<HTMLDivElement>(null);
  const scorollToTop = (e: any) => {
    if (listRef.current)
      listRef.current.scrollBy({
        top: -50,
        behavior: "smooth",
      });
  };
  const scorollToBottom = (e: any) => {
    if (listRef.current)
      listRef.current.scrollBy({
        top: 50,
        behavior: "smooth",
      });
  };
  //scroll Up
  const [scrollIntervalTop, setScrollIntervalTop] =
    useState<NodeJS.Timeout | null>(null);

  const handleMouseDownTop = () => {
    // Start scrolling down continuously when the button is pressed
    setScrollIntervalTop(setInterval(scorollToTop, 100));
  };

  const handleMouseUpTop = () => {
    // Stop scrolling when the button is released
    if (scrollIntervalTop) {
      clearInterval(scrollIntervalTop);
      setScrollIntervalTop(null);
    }
  };
  //scroll Bottom
  const [scrollIntervalBottom, setScrollIntervalBottom] =
    useState<NodeJS.Timeout | null>(null);

  const handleMouseDownBottom = () => {
    // Start scrolling down continuously when the button is pressed
    setScrollIntervalBottom(setInterval(scorollToBottom, 100));
  };

  const handleMouseUpBottom = () => {
    // Stop scrolling when the button is released
    if (scrollIntervalBottom) {
      clearInterval(scrollIntervalBottom);
      setScrollIntervalBottom(null);
    }
  };

  return getMonitoringDataIsError ? (
    <div className="mt-2">
      <EmsAlert
        status="error"
        title="une erreur s'est produite"
        description=""
      />
    </div>
  ) : (
    <div className="py-16 ">
      <div className="flex justify-center">
        <Button
          onClick={scorollToTop}
          onMouseDown={handleMouseDownTop}
          onMouseUp={handleMouseUpTop}
          variant={"unstyled"}
        >
          <ChevronUpIcon boxSize={12} />
        </Button>
      </div>
      <div
        ref={listRef}
        className="list-wheater w-full flex flex-col gap-2 md:mx-auto md:px-4 h-[700px] overflow-hidden overflow-y-scroll"
      >
        {dataByHours.map((data4Hour: DataHour, index: number) => {
          return (
            <Skeleton isLoaded={monitoringData}>
              <WeatherItem
                maxProd={maxProd || 50}
                key={index}
                data={data4Hour}
              />
            </Skeleton>
          );
        })}
      </div>
      <div className="flex justify-center">
        <Button
          onClick={scorollToBottom}
          onMouseDown={handleMouseDownBottom}
          onMouseUp={handleMouseUpBottom}
          variant={"unstyled"}
        >
          <ChevronDownIcon boxSize={12} />
        </Button>
      </div>
    </div>
  );
};
