import React from "react";
//dpe

//vertical

import { ReactComponent as DPEClasseAV } from "../assets/img/coaching/dpe/verticale/classe-A.svg";
import { ReactComponent as DPEClasseBV } from "../assets/img/coaching/dpe/verticale/classe-B.svg";
import { ReactComponent as DPEClasseCV } from "../assets/img/coaching/dpe/verticale/classe-C.svg";
import { ReactComponent as DPEClasseDV } from "../assets/img/coaching/dpe/verticale/classe-D.svg";
import { ReactComponent as DPEClasseEV } from "../assets/img/coaching/dpe/verticale/classe-E.svg";
import { ReactComponent as DPEClasseFV } from "../assets/img/coaching/dpe/verticale/classe-F.svg";
import { ReactComponent as DPEClasseGV } from "../assets/img/coaching/dpe/verticale/classe-G.svg";
//hozizontal
import { ReactComponent as DPEClasseAH } from "../assets/img/coaching/dpe/horizontale/classe-A.svg";
import { ReactComponent as DPEClasseBH } from "../assets/img/coaching/dpe/horizontale/classe-B.svg";
import { ReactComponent as DPEClasseCH } from "../assets/img/coaching/dpe/horizontale/classe-C.svg";
import { ReactComponent as DPEClasseDH } from "../assets/img/coaching/dpe/horizontale/classe-D.svg";
import { ReactComponent as DPEClasseEH } from "../assets/img/coaching/dpe/horizontale/classe-E.svg";
import { ReactComponent as DPEClasseFH } from "../assets/img/coaching/dpe/horizontale/classe-F.svg";
import { ReactComponent as DPEClasseGH } from "../assets/img/coaching/dpe/horizontale/classe-G.svg";

//ges

//vertical

import { ReactComponent as GESClasseAV } from "../assets/img/coaching/ges/verticale/classe-A.svg";
import { ReactComponent as GESClasseBV } from "../assets/img/coaching/ges/verticale/classe-B.svg";
import { ReactComponent as GESClasseCV } from "../assets/img/coaching/ges/verticale/classe-C.svg";
import { ReactComponent as GESClasseDV } from "../assets/img/coaching/ges/verticale/classe-D.svg";
import { ReactComponent as GESClasseEV } from "../assets/img/coaching/ges/verticale/classe-E.svg";
import { ReactComponent as GESClasseFV } from "../assets/img/coaching/ges/verticale/classe-F.svg";
import { ReactComponent as GESClasseGV } from "../assets/img/coaching/ges/verticale/classe-G.svg";
//hozizontges
import { ReactComponent as GESClasseAH } from "../assets/img/coaching/ges/horizontale/classe-A.svg";
import { ReactComponent as GESClasseBH } from "../assets/img/coaching/ges/horizontale/classe-B.svg";
import { ReactComponent as GESClasseCH } from "../assets/img/coaching/ges/horizontale/classe-C.svg";
import { ReactComponent as GESClasseDH } from "../assets/img/coaching/ges/horizontale/classe-D.svg";
import { ReactComponent as GESClasseEH } from "../assets/img/coaching/ges/horizontale/classe-E.svg";
import { ReactComponent as GESClasseFH } from "../assets/img/coaching/ges/horizontale/classe-F.svg";
import { ReactComponent as GESClasseGH } from "../assets/img/coaching/ges/horizontale/classe-G.svg";
import { Icon } from "@chakra-ui/react";
interface DpeGesSVGProps {
  classe: string;
  vertical?: boolean;
  type: string;
}
export const DpeGesSVG: React.FC<DpeGesSVGProps> = ({
  classe,
  vertical = true,
  type,
}) => {
  // Déterminer la couleur en fonction de la valeur du DPE
  let iconDpe;
  let iconGes;
  if (classe == "A") {
    iconDpe = vertical ? DPEClasseAV : DPEClasseAH;
    iconGes = vertical ? GESClasseAV : GESClasseAH;
  } else if (classe == "B") {
    iconDpe = vertical ? DPEClasseBV : DPEClasseBH;
    iconGes = vertical ? GESClasseBV : GESClasseBH;
  } else if (classe == "C") {
    iconDpe = vertical ? DPEClasseCV : DPEClasseCH;
    iconGes = vertical ? GESClasseCV : GESClasseCH;
  } else if (classe == "D") {
    iconDpe = vertical ? DPEClasseDV : DPEClasseDH;
    iconGes = vertical ? GESClasseDV : GESClasseDH;
  } else if (classe == "E") {
    iconDpe = vertical ? DPEClasseEV : DPEClasseEH;
    iconGes = vertical ? GESClasseEV : GESClasseEH;
  } else if (classe == "F") {
    iconDpe = vertical ? DPEClasseFV : DPEClasseFH;
    iconGes = vertical ? GESClasseFV : GESClasseFH;
  } else if (classe == "G") {
    iconDpe = vertical ? DPEClasseGV : DPEClasseGH;
    iconGes = vertical ? GESClasseGV : GESClasseGH;
  } 

  return type=="GES" ? <Icon as={iconGes} boxSize={12} /> : <Icon as={iconDpe} boxSize={12}/>
};

export default DpeGesSVG;