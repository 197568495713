import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import AppRoutes from "./routes/Routes";
import { customTheme } from "./theme";
import { persistor, store } from "./store/config";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
const App: React.FC = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
        <Router>
            <AppRoutes />
        </Router>
        </PersistGate>
        
      </Provider>
    </ChakraProvider>
  );
};

export default App;
