import React from 'react';
import { Box } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout';
import '../assets/scss/components/WidgetCard.scss';

interface WidgetCardProps {
    title: string;
    subTitle: string;
    text?: React.ReactNode;
    componentProps?: any;
    topElement?: React.ReactNode;
    bottomElement?: React.ReactNode;
}

/**
 * Créé un composant Card avec la possibilité d'y ajouter un titre, un titre secondaire, un texte, ainsi que 2 éléments (facultatifs) comme des sous-composants.
 * @param title String
 * @param subTitle String
 * @param text String
 * @param topElement ?ChildNode
 * @param bottomElement ?ChildNode
 */
const WidgetCard: React.FC<WidgetCardProps> = ({ title, subTitle, text, topElement, bottomElement }) => {

    return (
        <Box className="card">
            { topElement }
            <Text className="card__title">{ title }</Text>
            <Text className="card__subtitle">{ subTitle }</Text>
            <Text className="card__text">{ text }</Text>
            { bottomElement }
        </Box>
    );
}

export default WidgetCard;
