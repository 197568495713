import React, { ReactNode } from "react";
import { EcoCoachingIndicator } from "./EcoCoachingIndicator";
import { COLORS } from "../utils/toolbox";
import { ReactComponent as Good } from "../assets/icons/icon-ifpen-good.svg";
import { ReactComponent as Verygood } from "../assets/icons/icon-ifpen-verygood.svg";
import { ReactComponent as Bad } from "../assets/icons/icon-ifpen-bad.svg"; 
import { InfoPopover } from "./InfoPopover";
interface EcoCoachingIndicatorType {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  text: ReactNode;
  value: string;
  color: COLORS;
  isLoaded: boolean;
}
interface EcoCoachingIndicatorsProps {
  meanWorstHour?: number;
  meanSurplusHour?: number;
  meanBestHour?: number;
}
export const EcoCoachingIndicators: React.FC<EcoCoachingIndicatorsProps> = ({
  meanBestHour,
  meanSurplusHour,
  meanWorstHour,
}) => {
  const indicators: EcoCoachingIndicatorType[] = [
    {
      color: COLORS.EMS_BLUE,
      icon: Good,
      isLoaded: true,
      text: (
        <div className="flex flex-col gap-4">
          <p>Surplus Solaire</p>
          <div>
            <InfoPopover
              header={<p>Surplus Solaire</p>}
              body={
                <div>
                  Consommation moyenne mesurée quand la production solaire
                  excède la consommation, plus celle-ci est élevée, plus votre
                  consommation sera locale
                </div>
              }
            />
          </div>
        </div>
      ),
      value:
        meanSurplusHour != undefined
          ? `${meanSurplusHour.toFixed(1) + " kWh"}`
          : "",
    },
    {
      color: COLORS.EMS_BLUE,
      icon: Verygood,
      isLoaded: true,
      text: (
        <div className="flex flex-col gap-4">
          <p>Heures de Pointes</p>
          <div>
            <InfoPopover
              header={<p>Heures de Pointes</p>}
              body={
                <div>
                  Consommation moyenne mesurée pendant les heures de pointes, en
                  la réduisant, vous diminuez votre impact sur le réseau
                  électrique
                </div>
              }
            />
          </div>
        </div>
      ),
      value:
        meanWorstHour != undefined
          ? `${meanWorstHour.toFixed(1) + " kWh"}`
          : "",
    },
    {
      color: COLORS.EMS_BLUE,
      icon: Bad,
      isLoaded: true,
      text: (
        <div className="flex flex-col gap-4">
          <p>Heures Décarbonnées</p>
          <div>
            <InfoPopover
              header={<p>Heures Décarbonnées</p>}
              body={
                <div>
                  Consommation moyenne mesurée pendant les heures décarbonnées,
                  quand celle-ci est élevée, cela signifie que vous consommez
                  une électricité faible en CO2
                </div>
              }
            />
          </div>
        </div>
      ),
      value:
        meanBestHour != undefined ? `${meanBestHour.toFixed(1) + " kWh"}` : "",
    },
  ];
  return (
    <div className="flex gap-2 md:gap-4 md:gap-4 justify-between md:justify-center ">
        {indicators.map((ind, index) => {
          return <EcoCoachingIndicator key={index} {...ind} />;
        })}
    </div>
  );
};
