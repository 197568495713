import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./ems.service";

export interface ResponseType {
  [key: string]:
    | string
    | number
    | Object
    | undefined
    | { [key: string]: ResponseType };
}

export interface BodyType {}
export interface User {
  id:string;
  groupe:string
}  

export const crudApi = createApi({
  reducerPath: "crudApi",
  baseQuery:  baseQueryWithReauth,
  endpoints: (builder) => ({
    getAll: builder.query<
      ResponseType[],
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({ url: `${request.uri}/all`, method: "GET" }),
      transformResponse: (rawResult: ResponseType[], meta) => {
        return rawResult;
      },
    }),
    getOne: builder.query<
      ResponseType  ,
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${request.uri}/${request.id}`,
        method: "GET",
      }),
    }),
    update: builder.mutation<
      ResponseType,
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${request.uri}`,
        method: "PUT",
        body: request.body,
      }),
    }),
    create: builder.mutation<
      ResponseType,
      {
        uri: string;
        body?: BodyType;
        id?: string;
      }
    >({
      query: (request) => ({
        url: `${request.uri}`,
        method: "POST",
        body: request.body,
      }),
    }), 
    delete: builder.mutation({
      query: (request) => ({
        url: `${request.uri}/${request.id}`,
        method: "DELETE",
      }),
    }),
    getByCritere: builder.mutation<
    ResponseType[],
    {
      uri: string;
      body?: BodyType;
      id?: string;
    }
  >({
    query: (request) => ({
      url: `${request.uri}/criteria`,
      method: "POST",
      body: request.body,
    }),
  }),
  }),
});
