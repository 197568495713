import {
  Button,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ReactComponent as Info } from "../assets/icons/Info.svg";
import { ReactNode } from "react";

interface InfoPopoverProps {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
}
export const InfoPopover: React.FC<InfoPopoverProps> = ({
  body,
  header,
  footer,
}) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label="show info"
          variant="ghost"
          icon={<Icon as={Info} boxSize={8} />}
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          {header && (
            <PopoverHeader className="font-bold">{header}</PopoverHeader>
          )}
          <PopoverCloseButton />
          {body && <PopoverBody>{body}</PopoverBody>}
          {footer && <PopoverFooter>{footer}</PopoverFooter>}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
