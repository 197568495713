 
import { usePagination } from "../hooks/usePagination";
import "../assets/scss/components/TablePaginator.scss"

interface PaginatorProps {
    onPageChange : (n : number  )=>void 
    totalCount : number
    siblingCount : number
    currentPage : number  
    pageSize : number
    className : string
}

export const TablePaginator: React.FC<PaginatorProps> = ({ onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className }) => { 



    
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  }) || [];

   // If there are less than 2 times in pagination range we shall not render the component
   if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }


  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  if(typeof lastPage =="number") {
    if(currentPage > lastPage) {
    
      onPageChange(lastPage);
    }
  }

   return (
  <ul
  className="pagination-container"
  >
     {/* Left navigation arrow */}
    <li
   // className="h-[32px] text-center my-auto mx-[4px] flex box-border items-center rounded-[16px] text-[14px] min-w-[32px] "
   className={ "pagination-item" + (currentPage == 1 ? " disabled" : "")}
      onClick={onPrevious}
    >
      <div className="arrow  left" />
    </li>
    {paginationRange.map(pageNumber => {
       
      // If the pageItem is a DOT, render the DOTS unicode character
      if (pageNumber === "...") {
        return <li className="pagination-item dots">&#8230;</li>;
      }
  
      // Render our Page Pills
      return (
        <li
        
          className={"pagination-item" + (pageNumber == currentPage ? " selected" : "")}
          onClick={() => {if( typeof pageNumber =="number" )  onPageChange(pageNumber) }}
        >
          {pageNumber}
        </li>
      );
    })}
    {/*  Right Navigation arrow */}
    <li
    className={ "pagination-item" + (currentPage == lastPage ? " disabled" : "")}
      onClick={onNext}
    >
      <div className="arrow right" />
    </li>
  </ul>
);






};
