import { theme as chakraTheme } from '@chakra-ui/react'
import { extendTheme } from "@chakra-ui/react"
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

// On inclus la font utilisée pour l'application avec des fonts de fallbacks dans notre thème.
const fonts = {
  ...chakraTheme.fonts,
  body: `"Titillium Web Regular", "Arial"`,
  heading: `"Titillium Web Regular", "Arial"`
}

//const customTheme = extendTheme(fonts)


// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(['menu', 'item'])

const Menu = helpers.defineMultiStyleConfig({
  baseStyle: {
    menu: {
      backgroundColor:"#003265"
    },
    item: {
      backgroundColor:"#003265"
    },
  },
})


export const customTheme = extendTheme({
  components: {
    Menu,
  },
  fonts
})


