import Panel from "../components/Panel";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "../assets/scss/pages/MySolarProduction.scss";
import LabeledBarChart from "../components/LabeledBarChart";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { COLORS, TimeLabels, getFormatedDatetime } from "../utils/toolbox";
import {
  useLazyGetManualMonitoringWithFilterDataQuery,
  useLazyGetMonitoringActualAndForecastDataQuery,
} from "../services/ems.service";
import EmsAlert from "../components/EmsAlert";
import { Box, Skeleton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { store } from "../store/config";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { Switcher } from "../components/Switcher";
import moment from "moment";
//svgs
import {ReactComponent as Sun} from "../assets/icons/ifpen-sun.svg";
import {ReactComponent as BatteryDown}  from "../assets/icons/ifpen-battery-down.svg" 
import {ReactComponent as Pylon}  from "../assets/icons/ifpen-pylon.svg";
ChartJS.register(CategoryScale, LinearScale, BarElement, zoomPlugin);

const MySolarConsumptionPage: React.FC = () => {
  const { unit, period, switcherDate, setSwitcherDate, setPeriod } =
    useSwitcherContext();

  const [
    getMonitoringWithForecast,
    {
      isError: monitoringWithForecastIsError,
      isLoading: monitoringWithForecastIsLoading,
    },
  ] = useLazyGetMonitoringActualAndForecastDataQuery();

  const manualMonitoringResponse =
    useLazyGetManualMonitoringWithFilterDataQuery();
  const [data, setData] = useState<any>(null);

  const topologyId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());

    const fetchData = async () => {
      const data = await getMonitoringWithForecast({
        date: moment(switcherDate).toDate(),
        topologyId: topologyId,
      });
      return data.data;
    };
    fetchData()
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.log(err));
  }, [switcherDate]);

  const fetchMonitorungManualData = async (
    startDateTime: Date,
    endDateTime: Date,
    aggregation: string
  ) => {
    const data = await manualMonitoringResponse[0]({
      startDateTime,
      endDateTime,
      aggregation,
      topologyId,
    });

    return data.data.data;
  };
  const getDataForLabel = (timelabel: TimeLabels) => {
    const dateLabel = new Date(timelabel.instant);
    switch (period) {
      case "day":
        if (Number(timelabel.label.split("h")[0]) < 4) return;
        let startDateTime = new Date(timelabel.instant);
        startDateTime.setHours(startDateTime.getHours() - 1);
        let endDateTime = new Date(timelabel.instant);
        let aggregation = "10m";
        fetchMonitorungManualData(startDateTime, endDateTime, aggregation)
          .then((res) => {
            setData({
              day: res,
            });
          })
          .catch((err) => console.log(err));
        break;
      case "week":
        dateLabel.setHours(dateLabel.getHours() + 2);
        setSwitcherDate(dateLabel);
        setPeriod("day");
        break;
      case "month":
        dateLabel.setHours(dateLabel.getHours() + 2);
        setSwitcherDate(dateLabel);
        setPeriod("day");
        break;
      case "year":
        dateLabel.setHours(dateLabel.getHours() + 2);
        setSwitcherDate(dateLabel);
        setPeriod("month");
        break;
      default:
        break;
    }

    return;
  };

  const labelsConsum: any[] = [];
  const pConsoFromProd: any[] = [];
  const pConsoFromBat: any[] = [];
  const pConsoFromGrid: any[] = [];

  const pConsoFromProdForecast: any[] = [];
  const pConsoFromBatForecast: any[] = [];
  const pConsoFromGridForecast: any[] = [];

  //Actual
  if (data && data.outputDTOActual && data.outputDTOActual[period]) {
    const actual = data.outputDTOActual;
    //conso
    actual[period]["pConsoFromProd" + unit].forEach((item: any) => {
      getFormatedDatetime(item, period, labelsConsum);
      pConsoFromProd.push(item.energy.toFixed(1));
    });

    actual[period]["pConsoFromGrid" + unit].forEach((item: any) => {
      pConsoFromGrid.push(item.energy.toFixed(1));
    });

    actual[period]["pConsoFromBat" + unit].forEach((item: any) => {
      pConsoFromBat.push(item.energy.toFixed(1));
    });
  }
  //forecast
  if (data && data.outputDTOForeacasted && data.outputDTOForeacasted[period]) {
    const forecasted = data.outputDTOForeacasted;
    const addItem = (array: any[], item: any) => {
      array.push(item.energy.toFixed(1));
    };

    forecasted[period]["pConsoFromProd" + unit].forEach((item: any) => {
      getFormatedDatetime(item, period, labelsConsum);
      addItem(pConsoFromProdForecast, item);
    });

    forecasted[period]["pConsoFromGrid" + unit].forEach((item: any) => {
      addItem(pConsoFromGridForecast, item);
    });

    forecasted[period]["pConsoFromBat" + unit].forEach((item: any) => {
      addItem(pConsoFromBatForecast, item);
    });
  }

  const graphConso = {
    labels: labelsConsum,
    datasets: [
      {
        key:4,
        label: "Autoconsommation directe",
        data: pConsoFromProd,
        image: Sun,
        darkImage:Sun,
        bgColor: COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0)",
        borderWidth: 0,
      },
      {
        key:4,
        label: "Prédiction autoconsommation directe",
        data: pConsoFromProdForecast,
        image: Sun,
        darkImage:Sun,
        bgColor:  COLORS.PROD,
        backgroundColor: "rgb(242, 125, 0, 0.5)",
        borderWidth: 0,
        isForecast : true
      },
      {
        key:5,
        label: "Décharge batterie",
        image: BatteryDown,
        darkImage:BatteryDown,
        data: pConsoFromBat,
        bgColor:  COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119)",
        borderWidth: 0,
      },
      {
        key:5,
        label: "Prédiction décharge batterie",
        image: BatteryDown,
        darkImage:BatteryDown,
        data: pConsoFromBatForecast,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119, 0.5)",
        borderWidth: 0,
        isForecast : true
      },
      {
        key:6,
        label: "Consommation réseau",
        image: Pylon,
        darkImage:Pylon,
        data: pConsoFromGrid,
        bgColor: COLORS.CONSO,
        backgroundColor:"rgb(224, 0, 18)",
        borderWidth: 0,
      },
      {
        key:6,
        label: "Prédiction consommation réseau",
        image: Pylon,
        darkImage:Pylon,
        data: pConsoFromGridForecast,
        bgColor: COLORS.CONSO,
        backgroundColor:"rgb(224, 0, 18, 0.5)",
        borderWidth: 0,
        isForecast : true
      },
    ],
  };
  return (
    <>
      <div className="w-full">
        <div className="container  w-full h-full flex flex-col items-center mx-auto pt-4 gap-2 md:gap-4 flex-col gap-2 hidden md:flex">
          <div className="w-1/2 flex">
            <Panel>
              <Switcher />
            </Panel>
          </div>
          {monitoringWithForecastIsError ||
          (manualMonitoringResponse[1] &&
            manualMonitoringResponse[1].isError) ? (
            <EmsAlert
              status="error"
              title={"Erreur lors de la récupération des données"}
              description={"Error"}
            ></EmsAlert>
          ) : (
            <div className="w-full">
              {monitoringWithForecastIsLoading ||
              (manualMonitoringResponse[1] &&
                manualMonitoringResponse[1].isLoading) ? (
                <Skeleton className="w-full mx-2 my-4 md:mb-5 md:mt-4">
                  <div className=" w-full h-fullmd:w-[400px]"></div>
                </Skeleton>
              ) : (
                <Panel className="w-full">
                  <LabeledBarChart
                    data={graphConso}
                    getDataForLabel={getDataForLabel}
                  />
                </Panel>
              )}
            </div>
          )}
        </div>
        <Box className="flex md:hidden">
          {monitoringWithForecastIsError ||
          (manualMonitoringResponse[1] &&
            manualMonitoringResponse[1].isError) ? (
            <EmsAlert
              status="error"
              title={"Erreur lors de la récupération des données"}
              description={"Error"}
            ></EmsAlert>
          ) : (
            <div className="p-2 w-full h-full md:px-4 py-auto flex">
              <Skeleton
                isLoaded={
                  !(
                    manualMonitoringResponse[1].isLoading ||
                    monitoringWithForecastIsLoading
                  )
                }
                className="flex my-auto w-full h-full"
              >
                <Panel className="w-[100%] h-full min-w-full  md:h-full">
                  <Switcher units={["kWh", "Euro"]}>
                    <LabeledBarChart
                      data={graphConso}
                      getDataForLabel={getDataForLabel}
                      displayCols
                    />
                  </Switcher>
                </Panel>
              </Skeleton>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default MySolarConsumptionPage;
