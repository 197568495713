import { Button } from "@chakra-ui/react";
import React, { CSSProperties, ReactNode } from "react";

interface CustomButtonProps {
    backgroundColor? : string
    colorScheme : string
    text:ReactNode
    onClick : ()=>void
    style :CSSProperties
}

export const CustomButton : React.FC<CustomButtonProps> = ({backgroundColor,text,onClick,style})=>{

    return <Button style={style} onClick={onClick} colorScheme={backgroundColor}>{text}</Button>

}