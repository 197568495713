import React, { useEffect, useState } from "react";
import { resetTopology } from "../reducers/topologyReducer";
import { ResponseType, topologyApi } from "../services/topology.service";
import { useDispatch } from "react-redux";
import { Navigate} from "react-router-dom";
import { chooseTopology } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { selectAuth } from "../reducers/authReducer";
export const RedirectPage: React.FC = () => {
  const [, setListeTopos] = useState<ResponseType[]>();
  const dispatch = useDispatch();
  const { useFindByOwnerMutation } = topologyApi;
  const [getListTopos, listToposResponse] = useFindByOwnerMutation();
  const auth = selectAuth(store.getState());
  const redirect = (data: any) => {
    if (data) {
      if (data.length == 0) {
        return <div>Vous n'avez pas de sites</div>;
      } else if (data.length == 1) {
        dispatch(chooseTopology({ id: data[0].id as string,batteryCapacity :  data[0].capacity }));
        return <Navigate to="/my-installation" />;
      } else {
        return <Navigate to="/list-sites" />;
      }
    } else {
      return <></>;
    }
  };
  useEffect(() => {
    getListTopos({
      uri: "fluxTopology",
      body: { id: auth?.user?.id + "" },
    })
      .then((res) => {
        if ("data" in res) setListeTopos(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [auth?.user]);

  useEffect(() => {
    dispatch(resetTopology());
  }, []);
  return listToposResponse.isError ? (
    <div>Erreur</div>
  ) : listToposResponse.isLoading ? (
    <div className="flex justify-center items-center">
 
    </div>
  ) : (
    redirect(listToposResponse.data)
  );
};
